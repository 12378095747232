.dataCardWrapper {
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-card-default);
  border-radius: var(--border-radius-default);
  background-color: var(--color-neutral-light-100);
  margin-bottom: 1.5em;
  overflow: scroll;

  form,
  form > * {
    height: auto !important;
    min-height: auto !important;
    margin: 0 !important;
  }

  tbody {
    tr,
    td {
      border-radius: 0 !important;
    }

    tr:last-child {
      td:first-child {
        border-bottom-left-radius: var(--border-radius-default) !important;
      }
      td:last-child {
        border-bottom-right-radius: var(--border-radius-default) !important;
      }
    }
  }
}

.dataCardTitle,
.dataCardTitleFixed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3em;
  padding: var(--padding-card-default);
  padding-bottom: 0.5em;
  margin-bottom: 1em;

  & > * {
    display: flex;
    align-items: center;
    gap: 1.5em;
  }
}

.dataCardTitleFixed {
  & > span:first-child {
    position: absolute;
    height: 4em;
  }

  & > span:last-child {
    position: absolute;
    right: 0;
    height: 4em;
    padding-right: 4em;
  }
}

.quantityBadge {
  background-color: var(--color-primary-50);
  padding: 0.5em 1em;
  border-radius: var(--border-radius-default);

  * {
    color: var(--color-primary);
  }

  :first-child {
    font-weight: var(--font-weight-bold) !important;
    padding-right: 0.5em;
  }
}

.dataCardEmpty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
