.filterTable {
  background: var(--color-background-white);
  border-radius: var(--border-radius-default);
  box-shadow: var(--shadow-main-default);
  position: absolute;
  min-width: 320px;
  top: 100%;
  margin-top: -10px;
  left: 32px;
  opacity: 0;
  padding: 16px;
  z-index: 15;
  visibility: hidden;
  transition: all 0.1s linear;

  &Content {
    border-top: 1px solid var(--color-neutral-200);
    margin: 16px 0 0;
    padding: 16px 0 0;
    max-height: 155px;
    overflow-y: auto;

    li {
      list-style: none;

      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }

    label {
      cursor: pointer;
      color: var(--color-grayish);
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
    }
  }

  &All {
    margin-bottom: 16px !important;

    label {
      color: var(--color-primary);
      gap: 8px;
      justify-content: flex-end;
    }
  }
}
