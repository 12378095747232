.page {
  display: flex;
  min-height: 100vh;
  height: 100%;
  background-color: var(--color-background);
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100hw;
  margin-left: var(--menu-width-default);
  flex: 1;

  form {
    height: 100vh;
  }
}

.headerContainer {
  display: flex;
  z-index: 100;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  gap: 1em;
  width: -webkit-fill-available;
  padding: var(--padding-boxed-header-default);
  background-color: var(--color-neutral-light-200);
  box-shadow: var(--shadow-header-default);
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
}

.headerMain {
  display: flex;
  align-items: center;
  gap: 1.5em;
}

.headerId {
  display: flex;

  & > * {
    color: var(--color-primary-200);
  }
}

.headerText {
  color: var(--color-primary);
}

.headerInfoWrapper {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.headerInfoColumns {
  display: flex;
  gap: 3em;
}

.headerDate {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.headerDateTitle {
  white-space: nowrap;
}

.dateEdit {
  cursor: pointer;

  &::after {
    opacity: 0;
    font-family: var(--font-icon);
    font-size: var(--font-size-xl);
    color: var(--color-grayish);
    content: '\e918';
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }
}

.contentWrapper {
  margin: 200px 2em 4em;
}

.headerActionWrapper {
  display: flex;
  align-items: center;
  gap: 2em;
}
