.page {
  display: flex;
  padding-bottom: 4em;
  background-color: var(--color-background);
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100vw;
  margin-left: var(--menu-width-default);
}

.animalInfo {
  padding: 0 2em;
}

.stickyHeader {
  background: var(--color-background);
  position: sticky;
  top: 0;
}
