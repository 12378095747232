.dropdown {
  position: relative;

  * {
    text-align: left;
  }

  &Items {
    border: 1px solid var(--color-primary-200);
    margin: 0;
    padding: 8px;
    list-style: none;
    position: absolute;
    background-color: var(--color-neutral-light-200);
    box-shadow: var(--shadow-main-default);
    border-radius: var(--border-radius-input);
    top: 48px;
    left: 8px;

    li {
      font-family: var(--font-family);
      font-size: var(--font-size-normal);
      color: var(--color-grayish);
      font-weight: var(--font-weight-semibold);
      border-radius: var(--border-radius-input);
      display: flex;
      padding: 12px 16px;
      white-space: nowrap;
      align-items: center;
      position: relative;

      i {
        padding-right: 5px;
      }

      &:hover {
        color: var(--color-primary);
        background-color: var(--color-neutral-200);
        cursor: pointer;
      }

      &.hasDivider {
        margin-top: 16px;

        &:before {
          content: '';
          background: var(--color-neutral-200);
          width: calc(100% - 16px);
          margin: 0 8px;
          height: 1px;
          position: absolute;
          left: 0;
          top: -8px;
        }
      }
    }
  }
}
