:root {
  --header-message-height: 33px;
}

.headerMessage {
  height: var(--header-message-height);

  &Background {
    background: var(--color-alert-light-200);
    box-shadow: var(--shadow-header-default);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--header-message-height);
    padding: 8px 0;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    left: 0;
  }

  p {
    color: var(--color-alert-dark);
    margin: 0;
    font-size: var(--font-size-normal);
  }
}
