.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.headerContainer {
  display: flex;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
  height: 82px;
  padding: 16px 32px;
  background-color: var(--color-neutral-light-200);
  box-shadow: var(--shadow-header-default);
  box-sizing: border-box;
  position: sticky;
  top: 0;
  gap: 16px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  gap: 32px;
}

.dropdown {
  width: 180px;
}

.logo {
  max-height: 70px;
  max-width: 64px;
}

.menu {
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  &Item {
    list-style: none;
    color: var(--color-primary-900);
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-default);
    letter-spacing: var(--letter-space-default);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 189px;
    height: 82px;
    transition: all 0.1s linear;

    &:hover {
      color: var(--color-primary-200);
    }
  }

  &ItemActive {
    background-color: var(--color-primary);
    color: var(--color-neutral-light-100);

    &:hover {
      color: var(--color-neutral-light-200);
    }
  }
}

.exportButton {
  background-color: var(--color-neutral-light-200);
  color: var(--color-primary);
  text-transform: none;

  &:hover {
    color: var(--color-primary-200);
    background-color: var(--color-primary-30);
  }
}

.launchButton {
  text-transform: none;

  &:hover {
    background-color: var(--color-primary-200);
  }
}

.pageContent {
  max-width: 1240px;
  margin: 0 auto;
  width: 100%;
  padding-top: 56px;
  padding-bottom: 100px;

  &Larger {
    max-width: none;
    width: clamp(1320px, 100%, 80vw);
  }
}
