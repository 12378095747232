.heading {
  font-size: var(--font-size-normal);
  color: var(--default-font-color);
  margin: 0;
}

.h1 {
  font-size: var(--font-size-12xl);
  font-weight: var(--font-weight-black);
  line-height: var(--line-height-14xl);
}

.h2 {
  font-size: var(--font-size-10xl);
  font-weight: var(--font-weight-extrabold);
  line-height: var(--line-height-12xl);
}

.h3 {
  font-size: var(--font-size-7xl);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-10xl);
}

.h4 {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-5xl);
}

.h5 {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-5xl);
  letter-spacing: var(--letter-space-default);
}

.h6 {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-5xl);
}
