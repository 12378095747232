@mixin helpText {
  height: 100%;
  background: var(--color-neutral-light-300);
  color: var(--color-neutral-900);
  align-items: center;
  display: flex;
  padding: 0 1em;
}

.inputContainer {
  display: flex;
  vertical-align: center;
  height: 48px;
  align-items: center;
  border: 1px solid var(--color-neutral-200);
  border-radius: var(--border-radius-input);
  width: 100%;
  transition: border 0.3s ease;

  &:hover {
    border-color: var(--color-grayish);
  }

  &:focus,
  &:focus-within {
    border-color: var(--color-primary-200);
  }
}

.disabled,
.readOnly {
  pointer-events: none;
  cursor: not-allowed;
  background-color: var(--color-neutral-light-500);
  border-color: var(--color-neutral-100) !important;

  & > input {
    color: var(--color-neutral-200);
  }

  & > div {
    border-left-color: var(--color-neutral-100);
  }
}

.icon {
  padding-left: 12px;
  cursor: pointer;

  & > img,
  svg {
    padding-top: 2px;
    width: 15px;
    height: 15px;
  }
}

.input,
.timeInput input {
  font-family: var(--font-family);
  font-size: var(--font-size-normal);
  color: var(--color-grayish-dark);
  padding: 2px 12px;
  outline: none;
  border: none;
  width: 100%;
  letter-spacing: var(--letter-space-sm);
  line-height: var(--line-height-lg);
  background: transparent;

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &::placeholder,
  &::-ms-input-placeholder {
    color: var(--color-neutral-300);
  }

  &[type='date'] {
    color: var(--color-neutral-500);

    &[data-keeper-edited] {
      color: var(--color-grayish-dark);
    }
  }
}

.timeInput input {
  width: auto !important;
}

.focus {
  border-color: var(--color-grayish);
}

.default {
  border-color: var(--color-neutral-200);
}

.error {
  border-color: var(--color-danger-light);

  &:hover {
    border-color: var(--color-danger-dark);
  }
}

.success {
  border-color: var(--color-success-light);

  &:hover {
    border-color: var(--color-success-light);
  }
}

.prependHelpText {
  @include helpText();
  border-right: 1px solid var(--color-neutral-200);
  border-radius: var(--border-radius-input) 0 0 var(--border-radius-input);
}

.appendHelpText {
  @include helpText();
  border-left: 1px solid var(--color-neutral-200);
  border-radius: 0 var(--border-radius-input) var(--border-radius-input) 0;
}

.searchInput {
  max-width: 300px;
  padding: 0 8px;
}

input::placeholder {
  color: var(--color-neutral-300);
}
