.buttonContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 2em;
}

.modalClose {
  color: var(--color-grayish);
  position: absolute;
  right: 8px;
  top: 10px;
}

.modalText {
  font-size: var(--font-size-lg);
  color: var(--color-grayish);
  white-space: break-spaces;
}

.modalHeaderText {
  font-size: var(--font-size-xl);
  color: var(--color-grayish);
  max-width: 700px;
}

.primaryButton {
  font-size: var(--font-size-lg);
}
