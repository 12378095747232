.parameters {
  background: var(--color-neutral-light-100);
  border-radius: var(--border-radius-default);
  box-shadow: var(--shadow-card-default);
  margin: 32px 0;
  display: flex;

  &Sidebar {
    border-right: var(--border-separator);
    width: 320px;
    padding: 32px 16px;

    &Divider {
      border: 0;
      border-top: var(--border-separator);
      margin: 32px -16px;
    }

    h5 {
      text-wrap: balance;
    }
  }

  &List {
    flex: 1;
    padding: 32px 16px;
  }

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;
  }

  &Actions {
    display: flex;
    align-items: center;
    gap: 16px;

    button {
      text-transform: none;
      font-size: var(--font-size-normal);
    }
  }
}

.tabList {
  padding: 0;
  margin: 16px 0 0;
}

.tab {
  color: var(--color-grayish);
  font-weight: var(--font-weight-semibold);
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 8px 4px 8px 12px;
  flex: 1;
  margin-bottom: 8px;
  border-radius: var(--border-radius-default);
  transition: all 0.1s linear;
  cursor: pointer;

  &:hover {
    background-color: var(--color-primary-30);

    i {
      opacity: 1;
    }
  }

  i {
    transition: all 0.1s linear;
    opacity: 0;
  }
}

.tabActive {
  background-color: var(--color-primary-30);

  i {
    opacity: 1;
  }
}

.formActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 48px;

  & button {
    text-transform: none;
  }
}

.servicesAlert {
  background: var(--color-alert-light-700);
  border-radius: var(--border-radius-default);
  color: var(--color-grayish);
  display: flex;
  align-items: center;
  padding: 6px 12px;
  gap: 6px;

  p {
    margin: 0;
    color: var(--color-grayish);
    font-size: var(--font-size-normal);
  }
}

.servicesCard {
  border: 1px solid var(--color-neutral-200);
  border-radius: var(--border-radius-default);
  padding: 24px 16px;
  max-height: 200px;
  overflow: auto;

  p {
    color: var(--color-primary);
    font-size: var(--font-size-normal);
    margin: 0;
  }

  ul {
    padding: 0;
    list-style: none;
    margin: 16px 0;

    &:last-of-type {
      margin-bottom: 0;
    }

    li {
      color: var(--color-grayish);
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-medium);

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    .farmName {
      color: var(--color-primary-200);
      display: inline;
      margin: 0;
      font-size: 80%;
    }
  }

  span {
    display: flex;
    margin-bottom: 16px;
  }

  &Resume {
    text-align: center;
    max-height: none;

    p {
      color: var(--color-grayish-light);
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-normal);
    }

    h4 {
      color: var(--color-primary);
      margin: 8px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    h5 {
      color: var(--color-primary);
      margin: 0;
      line-height: var(--line-height-4xl);
    }
  }
}

.importModal {
  text-align: center;
  max-width: 520px;
  margin-bottom: -32px;

  h2 {
    color: var(--color-primary);
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-5xl);
    text-wrap: balance;
  }

  p {
    color: var(--color-grayish);
    font-size: var(--font-size-normal);
    line-height: var(--line-height-default);
    text-wrap: balance;
  }

  &Changes {
    color: var(--color-neutral-900);
    max-width: 300px;
    margin: 0 auto 16px;
    list-style: none;
    padding: 0;

    li {
      font-size: var(--font-size-lg);
      line-height: var(--line-height-xl);
      padding: 1px 0;
      margin-bottom: 8px;
    }
  }

  &Changed {
    background: var(--color-primary-30);
    color: var(--color-primary);
  }

  &History {
    padding: 0;
    list-style: none;
    max-height: 291px;
    overflow-y: auto;
    padding-right: 15px;

    li {
      margin-bottom: 16px;
    }

    button {
      font-size: var(--font-size-normal);
      text-transform: none;
      width: 100%;
      justify-content: center;
    }
  }

  hr {
    margin: 0;
  }

  &Actions {
    display: flex;
    flex-direction: column;
    gap: 8px;

    button {
      font-size: var(--font-size-normal);
      text-transform: none;
      width: 100%;
      justify-content: center;
    }
  }
}

.emptyState {
  background-color: var(--color-neutral-light-500);
  padding: 6px 12px;
  border-radius: var(--border-radius-default);
  margin: 24px;
}

.treeViewContainer {
  background: var(--color-neutral-light-200);
  border-radius: var(--border-radius-input);
  padding: 16px 16px 8px;

  ol {
    margin-bottom: 16px;
  }

  li {
    font-size: var(--font-size-lg);
    letter-spacing: 0;
  }
}
