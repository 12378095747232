.table {
  margin-top: 32px;
  border-spacing: 0px;

  & tr th {
    border-top: 1px solid var(--color-neutral-200);
    background-color: var(--color-neutral-light-300);
    color: var(--color-primary);
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-default);
    text-align: center;
    margin-bottom: 16px;
    text-wrap: balance;
  }

  & tr td,
  & tr th {
    border-right: 1px solid var(--color-neutral-200);
    border-bottom: 1px solid var(--color-neutral-200);
    padding: 8px;

    &:first-child {
      border-left: 1px solid var(--color-neutral-200);
    }
  }

  & tr:first-child {
    & th:first-child {
      border-top-left-radius: var(--border-radius-default);
    }

    & th:last-child {
      border-top-right-radius: var(--border-radius-default);
    }
  }

  & tr:last-child {
    & td:first-child {
      border-bottom-left-radius: var(--border-radius-default);
    }

    & td:last-child {
      border-bottom-right-radius: var(--border-radius-default);
    }
  }

  & tr td {
    color: var(--color-grayish);
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-normal);
    line-height: var(--line-height-5xl);
    text-align: center;
    margin-top: 32px;

    &.tableTotal {
      font-weight: var(--font-weight-bold);
      padding: 8px 16px;
    }

    &.tableFarm {
      font-size: var(--font-size-normal);
      font-weight: var(--font-weight-medium);
      padding: 8px 16px;
    }
  }
}

.tableChip {
  max-height: 24px;
  padding: 4px 12px;
}
