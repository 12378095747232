:root {
  /**  ##### Colors ####### */
  /* Main Colors */
  --color-primary-900: #374635;
  --color-primary: #415e3f;
  --color-primary-500: #415e3f;
  --color-primary-200: #6a9068;
  --color-primary-100: #94ba92;
  --color-primary-50: #c6dec5;
  --color-primary-30: #edf9ec;

  /* Neutral Colors */
  --color-grayish-dark: #363636;
  --color-grayish: #606062;
  --color-grayish-light: #949494;
  --color-grayish-200: #949494;

  --color-background-white: #fff;
  --color-background: #efefef;
  --color-background-dark-1: #f8f8f8;
  --color-background-dark-2: #f0f0f0;

  --color-neutral-darkest: #151515;
  --color-neutral-darker: #2a2a2a;
  --color-neutral-dark: #3d3d3d;
  --color-neutral-900: #7d7d7d;
  --color-neutral: #a0a0a0;
  --color-neutral-500: #a0a0a0;
  --color-neutral-300: #b8b8b8;
  --color-neutral-200: #c2c2c2;
  --color-neutral-100: #e8e8e8;
  --color-neutral-light-500: #f0f0f0;
  --color-neutral-light-300: #f3f3f3;
  --color-neutral-light-200: #f8f8f8;
  --color-neutral-light-100: #fff;

  --color-blackish-dark: #151515;
  --color-blackish: #2b2b2b;
  --color-blackish-light: #3d3d3d;

  --color-success-dark: #508a47;
  --color-success: #58b149;
  --color-success-light: #8dd082;

  --color-info-dark: #2280a8;
  --color-info: #46a7d0;
  --color-info-light: #72bfe0;
  --color-info-light-500: rgba(114, 191, 224, .5);
  --color-info-light-200: rgba(114, 191, 224, .2);

  --color-alert-dark: #645400;
  --color-alert: #f3d430;
  --color-alert-light: #fee873;
  --color-alert-light-700: rgba(254, 232, 115, 0.2);
  --color-alert-light-200: #fdffd7;

  --color-danger-dark: #c22828;
  --color-danger: #f33a3a;
  --color-danger-light: #f47d7d;
  --color-danger-table: #ffdcdc;
  --color-danger-table-hover: #f7c3c3;

  --color-warning: #da7c48;

  --color-border-primary-dark: #899687;
  --color-border-primary: #b8cbb7;
  --color-border-primary-light: #d7e5d6;
  --color-border-menu-hairline: #d7e5d630;
  --color-border-table-hairline: #c2c2c270;

  --color-white-ghost: rgba(255, 255, 255, 0.1);

  /* Table Colors */
  --color-table-even: #f3f3f3;
  --color-table-hover: var(--color-neutral-100);

  /**  ##### Borders ####### */
  --border-radius-small: 1px;
  --border-radius-default: 8px;
  --border-radius-large: 16px;
  --border-radius-input: 4px;
  --border-radius-chip: 100px;

  /* Border Colors */
  --border-primary-dark: 1px solid var(--color-primary-900);
  --border-primary: 1px solid var(--color-primary-500);
  --border-primary-light: 1px solid var(--color-primary-200);
  --border-primary-lighter: 1px solid var(--color-primary-100);
  --border-menu-hairline: 1px solid var(--color-border-menu-hairline);
  --border-table-hairline: 1px solid var(--color-border-table-hairline);

  --border-secondary-dark: 1px solid var(--color-grayish-dark);
  --border-secondary: 1px solid var(--color-grayish);
  --border-secondary-light: 1px solid var(--color-grayish-light);

  /* Border Neutral */
  --border-blackish-dark: 1px solid var(--color-blackish-dark);
  --border-blackish: 1px solid var(--color-blackish);
  --border-blackish-light: 1px solid var(--color-blackish-light);

  --border-grayish-dark: 1px solid var(--color-grayish-dark);
  --border-grayish: 1px solid var(--color-grayish);
  --border-grayish-light: 1px solid var(--color-grayish-light);

  --border-neutral-dark: 1px solid var(--color-neutral-dark);
  --border-neutral: 1px solid var(--color-neutral);
  --border-neutral-light: 1px solid var(--color-neutral-light-500);

  --border-separator: 1px solid var(--color-neutral-200);

  /* Border Alert */
  --border-danger-secondary-dark: 1px solid var(--color-danger-dark);
  --border-danger-secondary: 1px solid var(--color-danger);
  --border-danger-secondary-light: 1px solid var(--color-danger-light);

  /**  ##### Typography ####### */
  --font-family: 'Inter', sans-serif;
  --font-icon: 'ckl-icon';
  --font-style: normal;

  --font-size-12xl: 4rem; /** 64px **/
  --font-size-10xl: 2.75rem; /** 44px **/
  --font-size-9xl: 2.5rem; /** 40px **/
  --font-size-7xl: 2rem; /** 32px */
  --font-size-4xl: 1.5rem; /**  24px */
  --font-size-3xl: 1.375rem; /**  22px */
  --font-size-2xl: 1.25rem; /**  20px */
  --font-size-xl: 1.215rem; /**  18px */
  --font-size-lg: 1rem; /**  16px */
  --font-size-normal: 0.875rem; /** 14px */
  --font-size-xsm: 0.75rem; /**  12px */

  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;
  --font-weight-black: 900;

  --line-height-default: 1.215rem; /**  18px */

  --line-height-xs: 0.75rem; /**  12px */
  --line-height-sm: 1rem; /**  16px */
  --line-height-lg: 1.25rem; /**  20px */
  --line-height-xl: 1.375rem; /**  22px */
  --line-height-2xl: 1.5rem; /**  24px */
  --line-height-5xl: 1.875rem; /**  30px */
  --line-height-7xl: 2.25rem; /**  36px */
  --line-height-10xl: 2.625rem; /**  42px */
  --line-height-12xl: 3rem; /**  48px */
  --line-height-14xl: 3.375rem; /**  54px */

  --letter-space-default: 0.777778px; /**  18px */

  --letter-space-xs: 0.3px; /**  12px */
  --letter-space-sm: 0.75px; /**  14px */
  --letter-space-lg: 1px; /**  20px */

  --default-font-color: var(--color-grayish);

  /**  ##### Shadows ####### */
  --shadow-main-default: 0px 4px 4px rgba(0, 0, 0, 0.1);
  --shadow-header-default: 0px 2px 2px rgba(0, 0, 0, 0.15);
  --shadow-footer-default: 0px -2px 2px rgba(0, 0, 0, 0.15);
  --shadow-card-default: 0px 2px 2px rgba(0, 0, 0, 0.15);

  /**  ##### Spacing ####### */
  /* Padding */
  --padding-main-default: 4.25em 4.85em;
  --padding-header-default: 2.5em 2em;
  --padding-boxed-header-default: 1.5em;
  --padding-boxed-footer-default: 1.5em 2em;
  --padding-card-default: 1em 2em;

  /* Components Sizes */
  --menu-width-default: 230px;
  --menu-width-default-margin-adjust: calc(var(--menu-width-default) + 80px);
  --input-width-default: 480px;
  --boxed-layout-top-height: 136px;
  --boxed-layout-top-negative-margin: -58px;
  --boxed-layout-sticky-scrolling-space: 150px;
  --boxed-table-head-top-default: 224px;
}
