.selectedCalf {
  border: 1px solid var(--color-neutral-200);
  border-radius: var(--border-radius-default);
  padding: 12px 24px;
  margin: 20px 0;

  &Row {
    gap: 30px;
    margin-bottom: 5px;
  }

  & p {
    font-weight: var(--font-weight-semibold);
    color: var(--color-grayish);
    margin: 0;
  }
}

.modalSteps {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  &Button {
    font-size: var(--font-size-normal);
    color: var(--color-neutral);
    font-weight: var(--font-weight-semibold);
    background: none;
    border: 0;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 10px;

    &Active {
      color: var(--color-grayish);

      span {
        box-shadow: 0px 0px 0px 3px var(--color-primary-100);
        border-color: #fff;
        background: var(--color-primary-100);
        color: #fff;
      }
    }

    &Complete {
      color: var(--color-grayish);

      span {
        border-color: var(--color-primary-200);
        background: var(--color-primary-200);
        box-shadow: 0px 0px 0px 3px var(--color-primary-200);
        color: #fff;
      }
    }

    &Number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-bold);
      border: 3px solid var(--color-neutral-200);

      &Alert {
        border-color: var(--color-alert) !important;
        background: var(--color-alert) !important;
        box-shadow: 0px 0px 0px 3px var(--color-alert) !important;
      }
    }
  }

  &Separator {
    background: var(--color-neutral-200);
    display: block;
    height: 1px;
    width: 300px;
  }
}

.matingRecommendationForm {
  width: 900px;
    
  hr {
    margin-bottom: 0;
  }

  &Fields {
    display: flex;
    gap: 30px;

    &Animal {
      flex: 1;
      color: var(--color-grayish);
      font-weight: var(--font-weight-semibold);
      line-height: var(--line-height-default);
      font-size: var(--font-size-normal);

      label {
        margin: 0;
      }

      & > label {
        display: flex;
        margin: 6px 4px;
      }

      & > div {
        display: flex;
      
        & > div:first-child {
          flex: 1;
        }

        & > div:last-child {
          background: var(--color-neutral-light-300);
          border: 1px solid var(--color-neutral-200);
          border-radius: 0 var(--border-radius-default) var(--border-radius-default) 0;
          display: flex;
          justify-content: center;
          padding: 0 12px;
          max-height: 48px;
        }
      }
    }
  }
}

.animalIsNotLinked {
  margin-top: 15px;
  background: var(--color-neutral-light-500);
  border-radius: var(--border-radius-default);
  padding: 10px 15px;
  color: var(--color-grayish);
  font-size: var(--font-size-normal);
}

.animalLinked {
  margin-top: 15px;
  background: var(--color-primary-30);
  border-radius: var(--border-radius-default);
  padding: 10px 15px;
  color: var(--color-primary-900);
  font-size: var(--font-size-normal);
}

:global(.p-autocomplete) {
  display: flex;
  height: 48px;
  align-items: center;
  border: 1px solid var(--color-neutral-200);
  border-radius: var(--border-radius-input) 0 0 var(--border-radius-input);
  border-right: 0;
  width: 100%;
  transition: border 0.3s ease;

  input {
    font-family: var(--font-family);
    font-size: var(--font-size-normal);
    color: var(--color-grayish-dark);
    padding: 13px 12px;
    outline: none;
    border: none;
    width: 100%;
    letter-spacing: var(--letter-space-sm);
    line-height: var(--line-height-lg);
    background: transparent;
  }
}

.moveToDisposal {
  border: 1px dashed var(--color-neutral-200); 
  padding: 10px 20px 20px;
  margin-top: 15px;
  border-radius: var(--border-radius-default);
}

:global(.p-autocomplete-loader) {
  right: 12px;
}

:global(.p-autocomplete-items) {
  background: var(--color-neutral-light-200);
  border: 1px solid var(--color-neutral-200);
  border-radius: 0 0 var(--border-radius-input) var(--border-radius-input);
}

:global(.p-autocomplete-item) {
  font-size: var(--font-size-normal);
  padding: 10px 12px;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    gap: 30px;
    list-style: none;
  }

  li {
    color: var(--color-grayish);
    font-weight: var(--font-weight-semibold);

    span {
      font-weight: var(--font-weight-normal);
      padding-left: 3px;
    }
  }

  &:hover {
    background: var(--color-neutral-light-500);
  }
}