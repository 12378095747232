.clickable {
  cursor: pointer;
}

.tooltip {
  cursor: help;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.35;
}