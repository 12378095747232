.entriesInfoCard {
  padding-bottom: 2em;
}

.entry {
  padding: 1.5em;
  overflow: hidden;

  &:nth-child(2n - 1) {
    background-color: var(--color-neutral-light-300);
  }

  p {
    font-weight: var(--font-weight-semibold);
    color: var(--color-grayish);
    margin: 0;
  }
}

.entryName {
  margin-bottom: 1em;
}

.entryDate {
  margin-left: -40px;
  margin-top: 8px;
  color: var(--color-grayish-light);
}
