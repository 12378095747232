.serviceOrder {
  border: 1px solid transparent;
  display: flex;
  justify-content: flex-start;
  padding: 16px 16px;
  box-shadow: var(--shadow-card-default);
  border-radius: var(--border-radius-default);
  background-color: var(--color-neutral-light-100);
  margin-top: 16px;
  cursor: pointer;
  transition: all 0.1s linear;

  & > * {
    display: flex;
    flex-direction: row;
  }

  & > div {
    gap: 0.75em;
  }

  p {
    font-weight: var(--font-weight-semibold);
    color: var(--color-grayish);
    margin: 0;
  }

  &Divider {
    height: 48px;
    border-left-color: var(--color-neutral-200);
  }

  &:hover {
    border-color: var(--color-primary-200);
  }

  &.draft {
    background: var(--color-alert-light-200);
  }
}

.serviceOrderContent {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: space-between;
  margin: 0 16px;
}

.serviceOrderColumn {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.serviceOrderInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &Icon {
    color: var(--color-primary-500);
    position: relative;
  }

  &Badge {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 12px;

    span {
      background: var(--color-primary-200);
      height: 2px;
      display: block;
      animation: iconLine 0.5s;

      &:nth-child(1) {
        width: 12px;
      }

      &:nth-child(2) {
        width: 8px;
      }

      &:nth-child(3) {
        width: 10px;
      }

      &:nth-child(4) {
        width: 6px;
      }
    }
  }

  &Text {
    color: var(--color-primary-500) !important;
    line-height: var(--line-height-3xl);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-lg);
  }

  &Layout {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 160px;
  }
}

.serviceTitle {
  text-transform: none;
  color: var(--color-neutral-500) !important;
}

.serviceInfo {
  text-transform: none;

  &Primary {
    color: var(--color-primary-500) !important;
  }

  &Comment {
    position: absolute;
    max-width: 40vh;
  }

  &Empty {
    color: var(--color-neutral-200) !important;
  }
}

.serviceInfoDraft {
  color: var(--color-danger-dark);
  display: flex;
  align-items: center;
  gap: 4px;

  &Text {
    color: var(--color-danger-dark) !important;
  }
}

.newOS {
  animation: newItem 2s 2;
}

@keyframes newItem {
  50% {
    border-color: var(--color-primary-200);
    background-color: var(--color-primary-30);
  }
}

@keyframes iconLine {
  0% {
    width: 0px;
  }
}

.observation {
  div > span:first-child {
    font-weight: var(--font-weight-semibold);
    color: var(--color-grayish);
    display: block;
    max-width: 155px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
