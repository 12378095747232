.label {
  color: var(--color-grayish);
  display: flex;
  font-size: var(--font-size-normal);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-default);
  margin: 6px 4px;
}

.precipitationFilters {
  display: flex;
  gap: 1em;
}

.selectPeriod {
  display: flex;
  gap: 1em;
  margin-left: auto;

  &EndDate {
    margin-top: 31px;
  }
}

.tableActions {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.modalForm {
  width: 900px;
  margin-top: 1em;

  &Row {
    display: flex;
    flex-direction: row;
    gap: 2em;
    margin-bottom: 1em;

    & > div {
      flex: 1;
    }
  }
}

.stationsLoading {
  & > span {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
  }
}