.userProfile {
  display: flex;
  position: relative;
  cursor: pointer;

  &Photo {
    height: 56px;
    justify-content: center;
  }

  &Box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor: auto;
    width: 298px;
    margin: 52px 0 0;
    list-style: none;
    position: absolute;
    background-color: var(--color-neutral-light-200);
    box-shadow: var(--shadow-main-default);
    border-radius: var(--border-radius-input);
    right: 0;
    border: var(--border-primary-light);


    &Title {
      height: 56px;
      justify-content: center;
    }
  }

  &Items {
    display: flex;
    flex-direction: column;
    width: 298px;
    gap: 16px;

    li {
      font-family: var(--font-family);
      font-size: var(--font-size-normal);
      color: var(--color-grayish);
      font-weight: var(--font-weight-semibold);
      display: flex;
      white-space: nowrap;
      gap: 6px;
      align-items: center;

      &:hover {
        color: var(--color-primary);
        background-color: transparent;
        cursor: pointer;
      }
    }
  }

  hr {
    border-top-color: var(-color-neutral-200);
    width: 298px - 32px
  }
}

.titlePhoto {
  width: 128px;
  height: 128px;
}

.titleName {
  color: var(--color-primary-500);
}

.titleFarm {
  padding-top: 16px;
  font-weight: var(--font-weight-normal);
  color: var(--color-grayish-dark);
}

.titleEmail {
  color: var(--color-neutral-900);
  font-weight: var(--font-weight-normal);
}