.card {
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  box-shadow: var(--shadow-card-default);
  border-radius: var(--border-radius-default);
  background-color: var(--color-neutral-light-100);
  margin-bottom: 1.5em;

  &Title {
    margin-bottom: 24px;
    font-size: var(--font-size-4xl);
  }

  &Header {
    display: flex;
    justify-content: space-between;
  }
}

.totalSection {
  margin-top: 24px;

  &Female {
    margin-top: 8px;
  }
}

.chartChipWrapper {
  display: flex;
  gap: 16px;
}
