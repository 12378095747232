.totalSectionTitle {
  color: var(--color-primary);
  text-transform: uppercase;
  line-height: var(--line-height-7xl);
  margin-bottom: 8px;
}

.totalSectionRow {
  display: flex;
  gap: 16px;
  flex-direction: row;

  &Item {
    border-radius: var(--border-radius-default);
    border: 1px solid var(--color-neutral-200);
    background: #fefffe;
    min-width: 175px;
    height: 88px;
    flex: 1;

    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &Active {
      border-radius: var(--border-radius-default);
      border: var(--border-neutral);
      background: var(--color-primary-50);
    }

    &Label {
      margin-right: auto;
      margin-top: 18px;
      margin-left: 14px;
    }

    &Value {
      margin-left: auto;
      margin-bottom: 14px;
      margin-right: 22px;
      color: var(--color-grayish-dark);
    }
  }
}
