.matingRecommendation {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  margin-bottom: 1.5em;

  p {
    font-weight: var(--font-weight-semibold);
    color: var(--color-grayish);
    margin: 0;
  }
}

.animalInfo {
  display: flex;
  gap: 1em;
  flex: 1;

  & > div:first-child {
    flex: none;
  }

  & > div:last-child {
    flex: 1
  }

  & button {
    width: 100%;
    justify-content: center;
  }
}

.lastCalfRow {
  margin-bottom: 0;
  gap: 2em;

  > div {
    flex: 1;
  }

  &:hover {
    cursor: pointer;
  }
}

.pullLeft {
  margin-left: auto;
}

.empty {
  color: var(--color-neutral-200) !important;
  text-align: center;
  font-weight: normal !important;
}

.emptyCard {
  padding: 2em 0;
}

@media (max-width: 1680px) {
  .animalInfo {
    flex-direction: column;

    & > div {
      flex: 1
    }
  }
}