.page {
  display: flex;
  background-color: var(--color-background-white);
}

.fixedHeight {
  height: 100vh;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1em;
  margin-bottom: 3em;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.subheader {
  color: var(--color-primary-200);
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100vw;
  margin-left: var(--menu-width-default);
  padding: var(--padding-main-default);

  form {
    height: 100vh;
  }
}
