.orderCell {
  color: var(--color-neutral-500);
}

.input {
  display: flex;
  align-items: center;
  gap: 0.5em;

  input {
    min-width: 200px !important;
  }

  input[type='date'],
  input[name='birth_weight'],
  input[name='current_weight'],
  input[name='breed_first_percentage'],
  input[name='breed_second_percentage'] {
    min-width: 90px !important;
  }
}

.seriesInput {
  flex-direction: column;
}

.errorMessage {
  font-size: var(--font-size-xsm);
  color: var(--color-danger);
}
