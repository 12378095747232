.advancedInfo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1em;
  margin-bottom: 1.5em;

  p {
    font-weight: var(--font-weight-semibold);
    color: var(--color-grayish);
    margin: 0;
  }
}

.empty {
  color: var(--color-neutral-200) !important;
}

.associationText {
  height: 32px;
}
