.chipsTitle {
  font-family: var(--font-family);
  color: var(--default-font-color);
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-normal);
  letter-spacing: var(--letter-space-default);
  margin: 6px 0 8px 0;
  color: var(--color-primary);
}

.chip {
  min-height: 32px;
}

.farmSelect {
  display: flex;
  flex-direction: column;
}

.chipsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
