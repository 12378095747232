.buttonGroup {
  display: flex;
  flex-direction: row;
  height: 32px;

  &Item {
    display: flex;
    padding: 4px 16px;
    background-color: var(--color-background-white);
    color: var(--color-grayish-light);
    border: 1px solid var(--color-primary);
    gap: 4px;
    align-items: center;
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-sm);
    cursor: pointer;

    &Left {
      border-radius: 4px 0 0 4px;
    }

    &Right {
      border-radius: 0 4px 4px 0;
    }

    &Active {
      background-color: var(--color-primary);
      color: var(--color-background-white);
      border: 0;
    }
  }
}
