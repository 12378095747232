@import 'config/theme/css-variables.css';
/* Font family CSS */
@import 'config/theme/font-face.css';
/* Icon Font family CSS */
@import 'components/icons/style.css';

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

iframe {
  pointer-events: none;
}

/* Toast style overrides */
:root {
  --toastify-color-info: var(--color-info);
  --toastify-color-success: var(--color-success);
  --toastify-color-warning: var(--color-alert);
  --toastify-color-error: var(--color-danger);
  --toastify-font-family: var(--font-family);
  --toastify-text-color-warning: var(--color-grayish-dark);
}
.Toastify__toast-body {
  font-weight: var(--font-weight-semibold);
}
.Toastify__close-button {
  align-self: center;
}
.Toastify__toast-theme--colored.Toastify__toast--warning
  .Toastify__close-button {
  color: var(--color-grayish-dark);
}
.Toastify__toast-icon {
  font-family: var(--font-icon);
  font-size: var(--font-size-3xl);
}
.Toastify__toast-icon > svg {
  display: none;
}
.Toastify__toast-theme--colored.Toastify__toast--success
  .Toastify__toast-icon::before {
  content: '\e903';
}
.Toastify__toast-theme--colored.Toastify__toast--warning
  .Toastify__toast-icon::before {
  content: '\e901';
}
.Toastify__toast-theme--colored.Toastify__toast--info
  .Toastify__toast-icon::before {
  content: '\e90e';
}
.Toastify__toast-theme--colored.Toastify__toast--error
  .Toastify__toast-icon::before {
  content: '\e917';
}

@keyframes react-loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

.react-loading-skeleton {
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block; /* Enable animation */

  background-color: var(--base-color);

  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;

  position: relative;
  user-select: none;
  overflow: hidden;
  z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
}

.react-loading-skeleton::after {
  content: ' ';
  display: var(--pseudo-element-display);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    90deg,
    var(--base-color),
    var(--highlight-color),
    var(--base-color)
  );
  transform: translateX(-100%);

  animation-name: react-loading-skeleton;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@media (prefers-reduced-motion) {
  .react-loading-skeleton {
    --pseudo-element-display: none; /* Disable animation */
  }
}
