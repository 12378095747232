.groupGeneral {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
}

.groupCount {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.groupsContainer {
  margin-top: 2.5em;
}

.groupExpandedContainer {
  display: flex;
  gap: 3em;
  padding: 0 2em 2em;
}

.value {
  margin: 0;
  color: var(--color-grayish);
  font-weight: var(--font-weight-semibold);
}
