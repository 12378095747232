.input {
  display: flex;
  align-items: center;
  gap: 0.5em;

  input {
    min-width: 200px !important;
  }
}

.action {
  display: flex;
  min-width: 190px;
}
