.ordensBodyHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  margin-bottom: 24px;
}

.ordensBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin-bottom: 96px;
}

.calendarBody {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.calendar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  min-width: 270px;
  color: var(--color-neutral-light-100);
  background-color: var(--color-primary);
  font-weight: var(--font-weight-semibold);
  height: 48px;
  text-transform: capitalize;

  &ButtonForward {
    border-radius: 0;
    min-width: 0px;
    padding-left: 0;
    padding-right: 0;

    span {
      padding: 0 12px !important;

      &:empty {
        display: none;
      }
    }

    &:hover {
      background-color: var(--color-primary-200);
    }

    &:disabled {
      background-color: var(--color-primary-900);
    }
  }

  &ButtonBack {
    border-radius: 8px 0 0 8px;
    min-width: 0px;
    padding-left: 0;
    padding-right: 0;

    span {
      padding: 0 12px;

      &:empty {
        display: none;
      }
    }

    &:hover {
      background-color: var(--color-primary-200);
    }
  }

  &ButtonSelect {
    display: flex;
    margin-left: 2px;
    border-radius: 0 8px 8px 0;
    height: 48px;
    justify-content: center;
    background-color: var(--color-primary);

    * {
      color: var(--color-neutral-light-100);
    }

    button {
      border-radius: 0 8px 8px 0;
      padding: 12px 16px;
      min-width: 130px;
      justify-content: center;

      &:hover {
        background-color: var(--color-primary-200);
        color: var(--color-neutral-light-100);
      }
    }

    ul {
      background: none;
      border: 0;
      margin: 42px 0 0 48px;
      border-radius: 0;
    }

    li {
      border-radius: 0;
      background-color: var(--color-primary);
      color: var(--color-neutral-light-100);

      &:first-child {
        border-radius: 8px 0px 0 0;
        border-top: 1px solid var(--color-primary-200);
      }

      &:last-child {
        border-radius: 0 0px 8px 8px;
      }

      &:hover {
        background-color: var(--color-primary-200);
        color: var(--color-neutral-light-100);
      }
    }
  }
}

.bulletin {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-items: center;
  border-radius: 16px;
  font-weight: var(--font-weight-normal);
  color: var(--color-primary-200);
  background-color: var(--color-neutral-light-100);
  text-align: center;
  width: fit-content;
  margin-top: 16px;
  padding: 8px 16px;
  gap: 8px;

  &Text {
    color: var(--color-primary-200);
  }
}

.findInput {
  display: flex;
  align-items: center;
  font-weight: var(--font-weight-normal);
  text-align: center;
  background-color: var(--color-neutral-light-100);
  border-radius: var(--border-radius-input);
  position: relative;
  margin-bottom: 8px;

  &Icon {
    padding-left: 8px;
    position: absolute;
    justify-content: left;
    opacity: 0.2;

    &:hover {
      opacity: 0.6;
    }
  }

  &Text {
    padding-left: 36px;
    width: 244px;
    transition: all 0.1s linear;

    &:focus {
      width: 420px;
    }
  }
}

.checkbox {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
