.protocolDrawerRow {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  align-items: flex-end;

  & label {
    color: var(--color-grayish);
    display: flex;
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-default);
    margin: 6px 4px;
  }
}

.protocolDrawerDay,
.protocolDrawerDose {
  width: 12.5%;
}

.protocolDrawerAction,
.protocolDrawerProvider {
  width: 30%;
}

.protocolDrawerRemove {
  padding-bottom: 10px;
  width: 15%;
}