.count {
  display: flex;
  justify-content: center;
  min-width: 7em;
  max-width: max-content;
  padding: 0.5em 1em;
  background-color: var(--color-primary-50);
  border-radius: var(--border-radius-default);

  * {
    color: var(--color-primary);
  }

  .container {
    display: flex;
    align-items: center;
    min-height: 24px;

    :first-child {
      font-weight: var(--font-weight-bold);
      padding-right: 0.5em;
    }
  }

  &.warning {
    background-color: var(--color-alert-light);
    padding: 0.5em 1em;
    border-radius: var(--border-radius-default);
    cursor: help;

    :first-child {
      font-weight: var(--font-weight-bold) !important;
      padding-right: 0.25em;
    }

    :nth-child(2n) {
      font-weight: var(--font-weight-bold) !important;
      padding-right: 0.25em;
    }

    * {
      color: var(--color-alert-dark);
    }

    &:hover {
      background-color: var(--color-alert);
    }
  }

  &.empty {
    background-color: var(--color-neutral-100);
    padding: 0.5em 1em;
    border-radius: var(--border-radius-default);

    * {
      color: var(--color-grayish);
    }
  }
}

.caption {
  font-family: var(--font-family);
  color: var(--default-font-color);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-normal);
  letter-spacing: var(--letter-space-default);
}
