.infoCard {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 40%;
  flex-wrap: nowrap;
  padding: var(--padding-card-default);
  box-shadow: var(--shadow-card-default);
  border-radius: var(--border-radius-default);
  background-color: var(--color-neutral-light-100);
  max-width: 100%;
  box-sizing: border-box;
}

.infoCardFullWidth {
  flex-basis: 100%;
}

.infoCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  border-bottom: var(--border-neutral-light);
}

.infoCardHeaderCollapsible {
  cursor: pointer;
}

.infoCardSubjectContainer {
  height: 1em;
  margin-bottom: 1em;
}

.infoCardRow {
  display: flex;
  flex-direction: row;
  gap: 3em;
  flex-wrap: wrap;
  margin-bottom: 1.5em;
}

.infoCardRowSingleItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  margin-bottom: 1.5em;
}

.infoCardColGroup {
  display: flex;
  flex-direction: column;
  gap: 2.5em;
}

.justifyEnd {
  justify-content: flex-end;
}

.infoCardCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;
}

.breedCol {
  background-color: var(--color-neutral-light-500);
  border-radius: var(--border-radius-large);
  padding: 1em;
  gap: 1.5em;
}