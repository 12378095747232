.card {
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  box-shadow: var(--shadow-card-default);
  border-radius: var(--border-radius-default);
  background-color: var(--color-neutral-light-100);
  margin-bottom: 1.5em;

  &Title {
    font-size: var(--font-size-4xl);
    margin-bottom: 16px;
  }

  &Header {
    margin-left: 32px;

    &Subtitle {
      color: var(--color-grayish);
      font-size: var(--font-size-normal);
      font-weight: var(--font-weight-semibold);
      line-height: var(--line-height-sm);
    }
  }
}

.chipsContainer {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}

.title {
  font-size: var(--font-size-4xl);
  margin-bottom: 24px;
  margin-left: 40px;
}

.divider {
  &Horizontal {
    border-top-color: var(--color-neutral-100);
    width: 100%;
    margin-top: 32px;
    margin-bottom: 40px;
  }
  &Vertical {
    border-right-color: var(--color-neutral-100);
  }
}

.pieChartsContainer {
  display: flex;
  gap: 16px;
}

.pieChart {
  flex: 1;

  &Footer {
    margin-left: auto;
    margin-right: auto;
    margin-top: 38px;
  }
}
