.caption {
  font-family: var(--font-family);
  color: var(--default-font-color);
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);
}

.caption1 {
  font-size: var(--font-size-4xl);
}

.caption2 {
  font-size: var(--font-size-xl);
}

.caption3 {
  font-size: var(--font-size-normal);
  letter-spacing: var(--letter-space-default);
}

.bold {
  font-weight: var(--font-weight-black);
}

.altColor {
  color: var(--color-primary-200);
  overflow: visible;
  white-space: nowrap;
}
