.evaluationForm {
  width: 900px;
  
  &Row {
    display: flex;
    gap: 2em;

    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  &Col input[type=radio] + span {
    justify-content: center;
    width: 70px;
  }

  &ColSingle {
    flex: 1
  }
}

.avg {
  
  & > span {
    display: flex;
    margin: 6px 4px;
    height: 20px;
  }

  &Container {
    background-color: var(--color-neutral-light-200);
    border: 1px solid var(--color-neutral-200);
    border-radius: var(--border-radius-input);
    color: var(--color-primary-100);
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;

    & p {
      color: var(--color-grayish);
    }
  }

  &HelperText {
    height: 100%;
    background: var(--color-neutral-light-300);
    color: var(--color-neutral-900);
    align-items: center;
    display: flex;
    padding: 0 1em;
    border-left: 1px solid var(--color-neutral-200);
    border-radius: 0 var(--border-radius-input) var(--border-radius-input) 0;
  }
}