.tooltip {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.tooltipIconContainer {
  position: relative;
  height: 22px;

  &::before {
    content: attr(data-hover);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 110%;
    z-index: 100;
    visibility: hidden;
    opacity: 0;

    width: 160px;
    padding: 0.5em 1em;
    white-space: normal;
    overflow: hidden;
    text-align: left;
    text-transform: none;
    background-color: var(--color-primary-30);
    color: var(--color-primary);
    font-size: var(--font-size-xsm);
    border-radius: var(--border-radius-input);
    box-shadow: var(--shadow-main-default);
    transition: opacity 0.5s ease-in-out;
  }

  &:hover:before {
    opacity: 1;
    visibility: visible;
  }
}

.tooltipIcon {
  color: var(--color-neutral-500);
  cursor: help;
}
