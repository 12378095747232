.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  gap: 16px;
  width: -webkit-fill-available;
  padding: var(--padding-boxed-footer-default);
  background-color: var(--color-neutral-light-200);
  box-shadow: var(--shadow-footer-default);
  box-sizing: border-box;
}

.currentPageItems {
  display: flex;
  align-items: center;
  gap: 4px;
}

.caption3 {
  font-family: var(--font-family);
  color: var(--default-font-color);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-normal);
  letter-spacing: var(--letter-space-default);
}
