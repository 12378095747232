.expandCard {
  display: flex;
  flex-direction: column;
  background-color: var(--color-neutral-light-100);
  box-shadow: var(--shadow-header-default);
  border-radius: var(--border-radius-default);
  margin-bottom: 1em;
  cursor: pointer;

  &:hover {
    background-color: var(--color-neutral-light-200);
  }

  &:active {
    background-color: var(--color-neutral-light-300);
  }

  button {
    &:hover {
      background-color: inherit;
    }
  }

  tbody {
    tr,
    td {
      border-radius: 0 !important;
    }

    tr:last-child {
      td:first-child {
        border-bottom-left-radius: var(--border-radius-default) !important;
      }
      td:last-child {
        border-bottom-right-radius: var(--border-radius-default) !important;
      }
    }
  }
}

.expandCardHeader {
  display: flex;
  justify-content: space-between;

  & > * {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 2em;
    gap: 2em;
  }
}

.expandCardDate {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;

  & > * {
    color: var(--color-primary);
  }
}

.expandCardContent {
  margin-top: 1em;
}

.expandCardType {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
}

.expandCardInfo {
  display: flex;

  & > * {
    flex: 1;
  }

  & > span {
    white-space: nowrap;
  }
}

.disableColEqualSize {
  & > * {
    flex: 0;
  }
}

.expandCardInfoRow {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.expandCardInfoColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.2em;

  & > * {
    line-height: var(--line-height-lg);
  }
}

.expandCardInfoColumnEvaluation {
  min-width: 35%;
}

.dateSeparator {
  display: block;
  min-width: 7px;
  height: 7px;
  background-color: var(--color-primary);
  border-radius: var(--border-radius-default);
}

.buttonWrapper {
  justify-content: flex-end;
  flex: 0;

  button {
    white-space: nowrap;
  }
}

.buttonIcon {
  color: var(--color-grayish);
}

.expandCardId {
  max-width: 115px;
  display: flex;

  & > * {
    color: var(--color-primary-200);
  }
}

.expandCardAnimalBySex {
  display: flex;
  gap: 1em;
  max-width: 200px;
}

.expandCardExpandButtonContainer {
  justify-content: flex-end;
  flex: 0;
}

.caption3 {
  font-family: var(--font-family);
  color: var(--default-font-color);
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-normal);
  letter-spacing: var(--letter-space-default);

  &--alt {
    color: var(--color-primary-500);
  }
}

.expandCardFarmInfo {
  display: flex;
  gap: 1em;
  align-items: center;

  .farmName {
    min-width: 30%;
  }

  [id^='farmActiveAnimals'] {
    min-width: 11em;
  }

  [id^='farmActiveSectors'] {
    min-width: 11em;
  }
}
