.paragraph {
  font-size: var(--font-size-lg);
  color: var(--color-grayish-light);
  font-weight: var(--font-weight-normal);
}

.semiBold {
  font-weight: var(--font-weight-bold);
}

.light {
  font-weight: var(--font-weight-normal);
}
