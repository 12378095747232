.entry {
  padding: 24px;

  &:nth-child(2n - 1) {
    background-color: var(--color-neutral-light-300);
  }

  p {
    color: var(--color-grayish);
  }

  &Info {
    font-weight: var(--font-weight-normal);
    margin: 0;
  }

  &Header {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 16px;
  }

  &Row {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: 16px 48px;

    p {
      font-weight: var(--font-weight-semibold);
      margin: 8px 0 0;
    }
  }

  &Col {
    overflow: hidden;

    p {
      max-width: 300px;
    }
  }
}
