.expandedSection {
  display: flex;
  gap: 2em;
  padding: 0 2em 2em;
}

.caption {
  font-family: var(--font-family);
  color: var(--default-font-color);
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-normal);
  letter-spacing: var(--letter-space-default);
  display: block;
  margin-bottom: 0.5em;
}


.emptyMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2em;
}
