.menuContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  min-height: 100vh;
  min-width: var(--menu-width-default);
  max-width: var(--menu-width-default);
  background-color: var(--color-primary);
  z-index: 100;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em 0 2.75em;
  background-color: transparent;
  border: 0;
  width: 100%;
  cursor: pointer;
}

.logo {
  width: 190px;
  filter: brightness(0) invert(1);
}

.menuGroup {
  border-bottom: var(--border-menu-hairline);
  padding: 1em 0;

  &:last-child {
    border-bottom: 0;
  }
}

.menuGroupLabelContainer {
  display: flex;
  margin: 1em;
}

.menuGroupLabel {
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);
  color: var(--color-neutral-200);
  letter-spacing: var(--letter-space-default);
  font-size: var(--font-size-normal);
}

.menuItem {
  display: flex;
  align-items: center;
  background: transparent;
  width: 100%;
  gap: 10px;
  padding: 1em 0.5em;
  margin: 0;
  border: 0;
  color: var(--color-neutral-light-500);
  cursor: pointer;
  transition: 0.1s;

  i {
    margin-left: 0.5em;
  }

  &:hover {
    background-color: var(--color-white-ghost);
  }

  &Logout {
    margin-bottom: var(--header-message-height);
  }
}

.submenuGroup {
  overflow: hidden;
  transition: 0.3s all;
}

.submenuGroupClose {
  max-height: 0;
}

.menuItemLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: var(--font-family);
  font-weight: var(--font-weight-semibold);
  color: var(--color-neutral-light-500);
  margin: 0;
}

.menuItemLabelWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.submenuItemLabel {
  text-align: left;
  margin-left: 2.675em;
  font-size: var(--font-size-normal);
}

.logout {
  &::after {
    display: block;
    float: right;
    font-family: var(--font-icon);
    font-size: var(--font-size-lg);
    color: var(--color-neutral-light-500);
    content: '\e906';
    padding-right: 1em;
  }
}

.disabled {
  color: var(--color-neutral-900);
  cursor: not-allowed;

  &:hover {
    background-color: inherit;
  }
}

.submenuGroup {
  display: flex;
  flex-direction: column;
}
