.page {
  display: flex;
  height: 100vh;
  background-color: var(--color-background-white);
}

.headerContainer {
  display: flex;
  flex-direction: row;
}

.header {
  display: flex;
  flex-direction: column;
}

.subheader {
  color: var(--color-grayish-light);
  line-height: var(--line-height-xl);
  white-space: break-spaces;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  flex: 1;
  padding-left: 10%;
  padding-right: 10%;

  .mainContentWrapper {
    align-self: center;
    width: var(--input-width-default);
  }
}

.aside {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  background-color: var(--color-primary);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10%;
}
