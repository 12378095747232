.farmsHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  &Button {
    background: var(--color-info-light-200);
    color: var(--color-grayish);
    font-size: var(--font-size-normal);
    line-height: var(--line-height-default);
    font-family: var(--font-family);
    padding: 8px 12px;
    border-radius: var(--border-radius-default);
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    border: 0;
    margin-left: auto;
    transition: all 0.1s linear;

    i {
      color: var(--color-info-dark);
    }

    b {
      display: block;
      color: var(--color-primary);
      text-decoration: underline;
    }

    &:hover {
      background: var(--color-info-light-500);
    }
  }
}
