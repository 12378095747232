.textareaContainer {
  display: flex;
  vertical-align: center;
  align-items: center;
  border: 1px solid var(--color-neutral-200);
  border-radius: var(--border-radius-input);
  width: 100%;
  transition: border 0.3s ease;

  &:hover {
    border-color: var(--color-grayish);
  }

  &:focus,
  &:focus-within {
    border-color: var(--color-primary-200);
  }
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  background-color: var(--color-neutral-light-500);
  border-color: var(--color-neutral-100) !important;

  & > input {
    color: var(--color-neutral-200);
  }

  & > div {
    border-left-color: var(--color-neutral-100);
  }
}

.textarea {
  font-family: var(--font-family);
  font-size: var(--font-size-normal);
  color: var(--color-grayish-dark);
  padding: 8px 12px;
  outline: none;
  border: none;
  width: 100%;
  height: 100px;
  letter-spacing: var(--letter-space-sm);
  line-height: var(--line-height-lg);
  background: transparent;
  resize: vertical;

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: var(--color-neutral-200);
  }

  &::placeholder,
  &::-ms-input-placeholder {
    color: var(--color-neutral-300);
  }
}

.focus {
  border-color: var(--color-grayish);
}

.default {
  border-color: var(--color-neutral-200);
}

.error {
  border-color: var(--color-danger-light);

  &:hover {
    border-color: var(--color-danger-dark);
  }
}

.success {
  border-color: var(--color-success-light);

  &:hover {
    border-color: var(--color-success-light);
  }
}
