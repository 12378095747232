.loadingHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3em;
  }
  
  .loadingCard {
    background-color: var(--color-neutral-light-100);
    box-shadow: var(--shadow-header-default);
    border-radius: var(--border-radius-default);
    margin-bottom: 1em;
    display: flex;
    gap: 6em;
    padding: 1.25em 2em 0.25em;
  
    & > * {
      flex: 1
    }
  
    & span {
      display: block;
  
      &:first-child {
        width: 100%;
      }
  
      &:last-child {
        width: 75%;
      }
    }
  
  }