.caption {
  display: flex;
  color: var(--color-primary-500);
  margin-top: 1.5em;
}

.measuringStationTable {
  .inactive {
    opacity: 0.7;
  }

  .statusCol {
    display: flex;
    justify-content: space-between;

    &Buttons {
      display: flex;
      gap: 1em;
      justify-content: space-between;
    }
  }
}

.measuringStationLoading {
  display: flex;
  flex-direction: column;
  text-align: center;

  &Card {
    box-shadow: var(--shadow-card-default);
    border-radius: var(--border-radius-default);
    background-color: var(--color-neutral-light-100);
    padding: 32px 24px;
    flex: 1;
    gap: 100px;
  }

  span {
    margin-bottom: 10px;
  }
}

.table {
  margin-top: 10px;

  &Title {
    padding-left: 10px;
  }
}
