$padding: 1.5rem;
$padding-sm: 0.5rem;

.table {
  border-spacing: 0;
  width: -webkit-fill-available;

  & thead {
    & th {
      padding: $padding $padding $padding-sm 0;
      border-bottom: var(--border-neutral);
      color: var(--color-primary);
      font-weight: var(--font-weight-medium);
      letter-spacing: var(--letter-space-default);
      text-transform: uppercase;
      z-index: 99;

      &.sticky {
        position: sticky;
        background-color: var(--color-background);
        top: 136px;
      }
    }
  }

  & tbody tr {
    &:last-of-type td {
      border: 0;
    }

    & td {
      border-bottom: var(--border-neutral-light);
      font-weight: var(--font-weight-semibold);
      padding: $padding;
      padding-left: 0;
    }

    &.loading {
      pointer-events: none;
    }
  }

  th {
    white-space: nowrap;
    font-size: var(--font-size-normal);
    padding: 0 1.5em 1.5em 1.5em !important;
    color: var(--color-primary-900) !important;
    border-bottom: var(--border-table-hairline) !important;
  }

  td {
    padding: 1.5em !important;
  }

  tbody tr {
    transition: 0.1s ease-out;
    color: var(--color-grayish);
    border-radius: var(--border-radius-default);
    cursor: pointer;

    &:nth-child(even) {
      background-color: var(--color-table-even);
    }

    &:hover {
      background-color: var(--color-table-hover);
    }

    td:first-child {
      border-top-left-radius: var(--border-radius-default);
      border-bottom-left-radius: var(--border-radius-default);
    }

    td:last-child {
      border-top-right-radius: var(--border-radius-default);
      border-bottom-right-radius: var(--border-radius-default);
    }
  }
}

.headerCell {
  display: flex;
  align-items: center;
}

.iconOrderContainer {
  margin-left: 4px;
  cursor: pointer;
  display: flex;
}

.rotate {
  transform: rotate(180deg);
}

.active {
  color: var(--color-primary);
}
