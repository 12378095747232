.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.headerContainer {
  z-index: 100;
  width: -webkit-fill-available;
  padding: 32px;
  background-color: var(--color-neutral-light-200);
  box-shadow: var(--shadow-header-default);
  box-sizing: border-box;
  position: fixed;
  top: 0;
}

.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 42px;
}

.whiteBox {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  background: #FEFFFE;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-left: 12vw;
  margin-right: 12vw;
  gap: 32px;
  margin-bottom: 118px;
}

.titleText {
  color: var(--color-grayish);
  line-height: var(--line-height-default);
  font-weight: var(--font-weight-medium);
  white-space: break-spaces;
  padding-top: 40px;
}

.containerBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 64px;
  gap: 32px;
}

.imagesBox {
  max-height: 70vh;
}

.textBox {
  height: 70vh;
  display: flex;
  justify-content: space-between;
  position: absolute;
  flex-direction: row;
  align-content: flex-end;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 32vw;
}

.imageBackground {
  height: 70vh;
  width: fit-content;
  width: 32vw;
  object-fit: cover;
  border-radius: 8px;
}

.serviceText {
  max-width: 14vw;
  color: white;
  font-weight: var(--font-weight-bold);
  margin: 20px 20px;
  text-align: left;
}

.ordersButton {
  border: 1px solid white;
  color: #FEFFFE;
  margin: 20px 20px;

  &:hover {
    border: 1px solid white;
    background-color: #FEFFFE;
  }
}

.dashboardButton {
  border: 1px solid white;
  color: #FEFFFE;
  margin: 20px 20px;

  &:hover {
    border: 1px solid white;
    background-color: #FEFFFE;
  }
}