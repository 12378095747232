.drawer {
  min-width: 740px;

  [class*='helperText'] {
    position: static;
  }
}

.tabs {
  border-bottom: var(--border-separator);
  display: flex;
  margin: 0 0 16px;
  padding: 0 0 24px;
  list-style: none;
  gap: 4px;

  li {
    flex: 1;
  }

  &Item {
    background: none;
    border-radius: 3px;
    border: var(--border-separator);
    color: var(--color-primary-200);
    text-transform: none;
    font-size: var(--font-size-normal);
    width: 100%;
    justify-content: center;
    padding: 16px 0;
    max-height: none;
    transition: all 0.1s linear;

    &:hover {
      background: var(--color-primary-30);
      color: var(--color-primary);
    }

    &Active {
      background: var(--color-primary-30);
      border-color: var(--color-primary-100);
      color: var(--color-primary);
    }
  }
}

.selectDate {
  display: grid;
  grid-template-columns: 80% 20%;
  gap: 18px;
  margin-bottom: 1em;
  align-items: end;

  input[type='date'] {
    background: transparent;
    color: transparent;
  }
}

.buttonDate {
  display: flex;
  width: 90%;
  justify-content: center;
  background-color: transparent;
  border: var(--border-primary-light);
  color: var(--color-grayish);
  font-size: var(--font-size-normal);
  box-sizing: border-box;
  text-align: center;
  align-items: center;
  max-height: 48px;
  border-radius: 4px;
  padding: 16px 12px;

  &:hover {
    color: var(--color-primary-200);
    background-color: var(--color-primary-30);
  }
}

.subForm {
  background-color: var(--color-primary-30);
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 1em;
}

.suggestText {
  color: var(--color-primary);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-normal);
  margin: 0;
}

.changeService {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &Text {
    margin-top: 8px;
    margin-bottom: 16px;
    margin-left: 16px;
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-xsm);
  }
}

.divider {
  width: calc(100% - 30px);
  margin: 0 15px;
}

.formatOption {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;

  &SubLabel {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--color-grayish-200);
  }
}

.formatOptionWithChip {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  flex: 1;

  &SubLabel {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--color-grayish-200);
    padding-right: 2px;
  }
}

.groupLabel {
  text-transform: none;
  padding-bottom: 8px;
  font-size: var(--font-size-normal);
}

.groupSubLabel {
  text-transform: none;
  padding-bottom: 8px;
  font-size: var(--font-size-normal);
  cursor: pointer;
}

.optionText {
  font-size: var(--font-size-xsm);
  color: var(--color-grayish-200);
  text-decoration: underline;
  font-weight: var(--font-weight-normal);
  width: 740px;
}

.durationSuggestions {
  display: flex;
  gap: 18px;

  .durationInput {
    margin-top: -3px;

    & > span {
      height: 48px;
    }
  }
}

.odometer {
  align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 16px;

  &Field {
    color: var(--color-grayish-200) !important;
  }
}

.manualDate {
  visibility: hidden;
  position: absolute;
}

.additionalComment {
  height: 64px;
  resize: none;
}

.save {
  display: flex;
  padding-top: 16px;
  justify-content: space-between;

  &Bulletin {
    display: flex;
    gap: 8px;
  }

  &Buttons {
    text-transform: none;
  }

  &ThrowButton {
    text-transform: none;

    &:hover {
      color: var(--color-primary-200);
      background-color: var(--color-primary-30);
    }
  }
}

.cancelButton {
  text-transform: none;

  &:hover {
    color: var(--color-primary-200);
    background-color: var(--color-primary-30);
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;

  &Save {
    display: flex;
    gap: 16px;
  }

  button {
    width: min-content;
    text-transform: none;
    padding: 12px 32px;
    white-space: nowrap;
  }
}

.treeView {
  &Label {
    margin-bottom: 8px;

    & span + span {
      font-weight: var(--font-weight-normal);
    }
  }

  &Container {
    background: var(--color-background-white);
    border: 1px solid var(--color-neutral-200);
    border-radius: var(--border-radius-input);
    padding: 16px 16px 8px;
    margin-bottom: 16px;
  }
}

:global(.react-select__option--is-selected),
:global(.react-select__option--is-selected.react-select__option--is-focused) {
  .formatOptionSubLabel,
  .formatOptionWithChipSubLabel {
    color: var(--color-background-white);
  }
}

:global(.react-select__option--is-focused) {
  .formatOptionSubLabel,
  .formatOptionWithChipSubLabel {
    color: var(--color-primary);
  }
}
