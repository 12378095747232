.selectButton {
  position: relative;

  &Items {
    margin: 5px 0 0;
    padding: 0;
    list-style: none;
    position: absolute;
    background-color: var(--color-neutral-light-200);
    box-shadow: var(--shadow-main-default);
    border-radius: var(--border-radius-input);
    right: 0;
    border: var(--border-neutral-light);
    z-index: 10;

    li {
      font-family: var(--font-family);
      font-size: var(--font-size-normal);
      color: var(--color-grayish);
      font-weight: var(--font-weight-semibold);
      border-radius: var(--border-radius-input);
      display: flex;
      padding: 0.75em 1em;
      padding-right: 2em;
      white-space: nowrap;
      align-items: center;

      i {
        padding-right: 5px;
      }

      &:hover {
        color: var(--color-primary);
        background-color: var(--color-neutral-200);
        cursor: pointer;
      }
    }
  }
}
