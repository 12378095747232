.recommendation {
  display: flex;
  box-shadow: var(--shadow-card-default);
  border-radius: var(--border-radius-default);
  background-color: var(--color-neutral-light-100);  
  padding: 1.5em;
  gap: 3em;
  cursor: pointer;
  margin-bottom: 1em;

  h5 {
    margin-bottom: 1em;
  }

  p {
    color: var(--color-grayish);
    font-weight: var(--font-weight-semibold);
    margin: 0.5em 0;
  }

  & > div {
    flex: 1
  }

  &Male {
    border-left: 1px solid var(--color-neutral-200);
    padding-left: 3em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &Items {
    display: flex;
    gap: 2em; 
  }
}