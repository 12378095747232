.farmCard {
  &Content {
    padding: 0 2em;
  }

  p {
    color: var(--color-grayish);
  }

  .caption {
    color: var(--color-primary-500);
  }

  .sectorTable {
    margin-top: 3em;

    .caption {
      display: flex;
      margin-top: 1.5em;
    }

    .activitiesCol {
      display: flex;
      gap: 1em;

      span {
        min-width: 5em;
      }
    }

    .statusCol {
      display: flex;
      gap: 1em;
      justify-content: space-between;
    }
  }
}
