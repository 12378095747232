.findAndCheckbox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.noDataFound {
  padding-top: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &Text {
    color: var(--color-grayish);
    font-weight: var(--font-weight-light);
  }
}

.serviceOrdersLoading {
  margin-top: 16px;

  .loadingCard {
    background-color: var(--color-neutral-light-100);
    box-shadow: var(--shadow-header-default);
    border-radius: var(--border-radius-default);
    margin-bottom: 1em;
    display: flex;
    gap: 6em;
    padding: 1.25em 2em 0.25em;

    & > * {
      flex: 1;
    }

    & span {
      display: block;

      &:first-child {
        width: 100%;
      }

      &:last-child {
        width: 75%;
      }
    }
  }
}

.notificationModal {
  text-align: center;
  margin-bottom: -32px;

  h2 {
    color: var(--color-primary);
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-5xl);
  }

  p {
    color: var(--color-grayish);
    font-size: var(--font-size-normal);
    line-height: var(--line-height-default);
    text-wrap: balance;
    margin-bottom: 32px;
  }

  hr {
    margin: 0;
  }

  button {
    font-size: var(--font-size-normal);
    text-transform: none;
    width: 100%;
    justify-content: center;
  }
}
