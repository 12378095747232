.card {
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  box-shadow: var(--shadow-card-default);
  border-radius: var(--border-radius-default);
  background-color: var(--color-neutral-light-100);
  margin-bottom: 1.5em;

  &Title {
    font-size: var(--font-size-4xl);
    margin-bottom: 40px;
  }

  &Header {
    margin-left: 32px;
  }
}

.yAxis {
  fill: var(--color-grayish);
  font-size: var(--font-size-normal);
  font-weight: var(--font-weight-medium);

  &Label {
    fill: var(--color-grayish);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-normal);
  }
}

.tooltip {
  background-color: var(--color-background-white);
  padding: 16px;
  border-radius: var(--border-radius-default);
  box-shadow: var(--shadow-main-default);
  display: flex;
  gap: 8px;
  flex-direction: column;
  background: #fefffe;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &Divider {
    border-top-color: var(--color-neutral-200);
    width: 100%;
    padding: 0;
    margin: 8px 0;
  }

  &Row {
    display: flex;
    flex-direction: row;
    height: 40px;
    gap: 8px;
  }

  &Box {
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
    border-radius: 16px;
  }

  &TextContainer {
    display: flex;
    flex-direction: column;
  }

  &Text {
    color: var(--color-grayish);
    font-size: var(--font-size-xsm);
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;
    line-height: var(--line-height-default);

    &Highlight {
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-lg);
      line-height: var(--line-height-2xl);
    }
  }
}
