.tabContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  gap: 40px;
  align-items: center;
}

.tab {
  color: var(--color-neutral-900);
  font-size: var(--font-size-normal);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-default);
  letter-spacing: var(--letter-space-default);
  text-transform: uppercase;
  cursor: pointer;

  &Active {
    color: var(--color-primary);
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: var(--color-primary);
      bottom: -8px;
      left: 0;
    }
  }
}

.dashboardLoading {
  display: flex;
  gap: 32px;
  flex-direction: column;
  text-align: center;

  &Card {
    box-shadow: var(--shadow-card-default);
    border-radius: var(--border-radius-default);
    background-color: var(--color-neutral-light-100);
    padding: 32px 24px;
    flex: 1;

    & > span {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 16px;
    }

    br {
      display: none;
    }
  }

  &CardSingleItem {
    & > span {
      display: block;
    }
  }
}
