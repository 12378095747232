.layoutSection {
  display: flex;
  flex-direction: column;
  gap: 0.75em;
}

.layoutSectionValues {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.25em;
}

.layoutSectionTitle {
  color: var(--color-primary);
  font-family: var(--font-family);
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-normal);
  letter-spacing: var(--letter-space-default);
}

.layoutSectionDateRange {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  &::after {
    opacity: 0;
    width: 0;
    font-family: var(--font-icon);
    font-size: var(--font-size-xm);
    color: var(--color-grayish);
    content: '\e918';
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }
}

.value,
.link {
  margin: 0;
  color: var(--color-grayish);
  font-weight: var(--font-weight-semibold);
}

.link {
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: var(--color-primary-200);
  }
}

.dateSeparator {
  display: block;
  min-width: 7px;
  height: 7px;
  background-color: var(--color-grayish);
  border-radius: var(--border-radius-default);
  margin: 0 0.8em;
}
