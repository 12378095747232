.input {
  display: flex;
  align-items: center;
  gap: 0.5em;

  input {
    min-width: 200px !important;
  }
}

.avgNumber,
.avgEmpty {
  min-width: 5em;
  display: block;
}

.avgEmpty {
  color: var(--color-neutral-500);
}

.productQuantityWrapper {
  input {
    min-width: 50px !important;
  }

  display: flex;
  flex-direction: column;
  gap: 0.55em;
}

.productNameList {
  display: flex;
  flex-direction: column;
  gap: 0.55em;
}

.productNameWrapper {
  position: relative;
}

.productNameButtonWrapper {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 5;
}

.productNameInput {
  display: flex;
  align-items: center;
}
