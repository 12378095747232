.contentLabelList {
  fill: var(--color-primary-900);
  font-size: var(--font-size-normal);
  font-weight: var(--font-weight-medium);
  pointer-events: none;
}

.stackLabel {
  fill: var(--color-grayish);
  font-size: var(--font-size-normal);
  font-weight: var(--font-weight-normal);
}

.tooltip {
  background-color: var(--color-background-white);
  padding: 8px;
  border-radius: var(--border-radius-default);
  box-shadow: var(--shadow-main-default);
  display: flex;
  gap: 8px;
  align-items: center;

  &Box {
    min-width: 16px;
    min-height: 16px;
    border-radius: 2px;
  }

  &Text {
    color: var(--color-grayish);
    font-size: var(--font-size-xsm);
    font-weight: var(--font-weight-medium);

    &Highlight {
      font-weight: var(--font-weight-bold);
    }
  }
}

.legend {
  display: flex;
  gap: 23px;
  margin-left: auto;
  margin-right: auto;

  &Content {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &Icon {
    min-width: 24px;
    min-height: 24px;
    border-radius: 2px;
  }

  &Text {
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-default);
    color: var(--color-grayish);
    text-transform: uppercase;
    text-wrap: balance;
  }
}

.xAxis {
  fill: var(--color-primary);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  font-size: var(--font-size-normal);
}

.yAxis {
  fill: var(--color-grayish);
  font-size: var(--font-size-normal);
  font-weight: var(--font-weight-medium);

  &Label {
    fill: var(--color-grayish);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-normal);
  }
}
