.animalEvaluation {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1em;
  margin-bottom: 1.5em;

  p {
    font-weight: var(--font-weight-semibold);
    color: var(--color-grayish);
    margin: 0;
  }
}

.empty {
  color: var(--color-neutral-200) !important;
  text-align: center;
  font-weight: normal !important;
}

.emptyCard {
  padding: 2em 0;
}

.evaluationFields {
  margin-top: 15px;
}

.animalHistory {
  flex-basis: 100%;
}
