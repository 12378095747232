.stationGeneral {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
}

.stationCount {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.stationsContainer {
  margin-top: 2.5em;
}

.stationExpandedContainer {
  display: flex;
  gap: 3em;
  padding: 0 2em 2em;
}

.value {
  margin: 0;
  color: var(--color-grayish);
  font-weight: var(--font-weight-semibold);
}

.breedingStationNameRow {
  margin-top: 10px;
}