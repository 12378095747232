.card {
  border: 1px solid var(--color-neutral-200);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 1.5em;
  box-shadow: var(--shadow-card-default);
  border-radius: var(--border-radius-default);
  background-color: var(--color-neutral-light-100);
  margin-bottom: 1em;

  & > div {
    margin-bottom: 0;
  }
}