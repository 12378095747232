.expandedContainer {
  display: flex;
  gap: 3em;
  margin: -1em 2em 1.5em;
  padding-top: 1.5em;
  border-top: var(--border-neutral-light);
}

.expandedCol {
  * {
    margin: 0.375em 0;
    color: var(--color-grayish);
  }
}
