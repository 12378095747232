@font-face {
  font-family: 'ckl-icon';
  src: url('fonts/ckl-icon.eot');
  src: url('fonts/ckl-icon.eot') format('embedded-opentype'),
    url('fonts/ckl-icon.ttf') format('truetype'),
    url('fonts/ckl-icon.woff') format('woff'),
    url('fonts/ckl-icon.svg#ckl-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ckl-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-font-add-circle-outline:before {
  content: '\e900';
}

.icon-font-alert-circle-outline:before {
  content: '\e901';
}

.icon-font-briefcase-outline:before {
  content: '\e902';
}

.icon-font-checkmark-circle-outline:before {
  content: '\e903';
}

.icon-font-chevron-back:before {
  content: '\e904';
}

.icon-font-chevron-down:before {
  content: '\e905';
}

.icon-font-chevron-forward:before {
  content: '\e906';
}

.icon-font-chevron-up:before {
  content: '\e907';
}

.icon-font-close:before {
  content: '\e908';
}

.icon-font-close-circle-outline:before {
  content: '\e909';
}

.icon-font-cow:before {
  content: '\e90a';
}

.icon-font-ellipsis-vertical:before {
  content: '\e90b';
}

.icon-font-exit-outline:before {
  content: '\e90c';
}

.icon-font-file-tray-full-outline:before {
  content: '\e90d';
}

.icon-font-information-circle-outline:before {
  content: '\e90e';
}

.icon-font-list-outline:before {
  content: '\e90f';
}

.icon-font-people-outline:before {
  content: '\e910';
}

.icon-font-person-circle-outline:before {
  content: '\e911';
}

.icon-font-radio-button-off:before {
  content: '\e912';
}

.icon-font-radio-button-on:before {
  content: '\e913';
}

.icon-font-ribbon-outline:before {
  content: '\e914';
}

.icon-font-settings-outline:before {
  content: '\e915';
}

.icon-font-star-outline:before {
  content: '\e916';
}

.icon-font-warning-outline:before {
  content: '\e917';
}

.icon-font-pencil:before {
  content: '\e918';
}

.icon-font-female:before {
  content: '\e919';
}

.icon-font-filter:before {
  content: '\e91a';
}

.icon-font-male:before {
  content: '\e91b';
}

.icon-font-search:before {
  content: '\e91c';
}

.icon-font-calendar:before {
  content: '\e91d';
}

.icon-font-download:before {
  content: '\e91e';
}

.icon-font-checkbox-unchecked:before {
  content: '\e91f';
}

.icon-font-checkbox-checked:before {
  content: '\e920';
}

.icon-font-add:before {
  content: '\e921';
}

.icon-font-remove:before {
  content: '\e922';
}

.icon-font-upload:before {
  content: '\e923';
}

.icon-font-dot:before {
  content: '\e924';
}

.icon-font-clipboard:before {
  content: '\e925';
}

.icon-font-folder:before {
  content: '\e926';
}

.icon-font-bar-chart-outline:before {
  content: '\e927';
}

.icon-font-loading:before {
  content: '\e928';
}