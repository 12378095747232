.modalText {
  background-color: var(--color-neutral-light-500);
  color: var(--color-grayish);
  font-size: 14px;
  margin-bottom: 15px;
  padding: 8px 12px;
  border-radius: var(--border-radius-default);
}

.formFieldsetField {
  margin-bottom: 15px;
}