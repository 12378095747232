.orderCell {
  color: var(--color-neutral-500);
}

.input {
  display: flex;
  align-items: center;
  gap: 0.5em;

  input {
    min-width: 200px !important;
  }

  input[name='birth_date'],
  input[name='birth_weight'],
  input[name='current_weight'],
  input[name='breed_first_percentage'],
  input[name='breed_second_percentage'] {
    min-width: 90px !important;
  }
}


.seriesInputError {
  margin-top: 20px;
}

.error {
  font-size: 10px;
  color: var(--color-danger);
}