.customHeader {
  display: flex;
  align-items: center;
}

.selectButton {
  * {
    color: var(--color-primary-900);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-normal);
    letter-spacing: var(--letter-space-default);
    text-transform: uppercase;
    text-align: left;
  }

  ul {
    margin-top: 0.5em;
    margin-left: 0.5em;
    z-index: 99;
  }
}

.actionsList {
  list-style: none;
  padding: 0;
  margin: 0;
  
  li {
    white-space: nowrap;
  }
}
