.userTable {
  td {
    color: var(--color-grayish-light);
    font-size: var(--font-size-normal);
    border: 0 !important;
  }

  tr > td:first-child {
    font-size: var(--font-size-lg);
    color: var(--color-grayish);
  }

  tbody tr {
    &:nth-child(even) {
      background-color: inherit !important;
    }

    &:hover {
      background-color: var(--color-table-even) !important;
    }
  }
}

.chipType {
  background-color: var(--color-primary-500) !important;

  &:hover {
    background-color: var(--color-primary-900) !important;
  }
}

.chipActive {
  background-color: var(--color-primary-100) !important;

  &:hover {
    background-color: var(--color-primary-200) !important;
  }
}

.nameDisabled {
  color: var(--color-neutral-500) !important;
}

.chipDisabled {
  background-color: var(--color-neutral-200) !important;
}

.actionMenuTriggerWrapper {
  padding: 0 !important;
}

.actionMenuTrigger {
  float: right;
}
