.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentLabelList {
  fill: var(--color-primary-900);
  font-size: var(--font-size-normal);
  font-weight: var(--font-weight-medium);
  pointer-events: none;
}

.container {
  margin-top: 50px;
  position: relative;
}

.tooltip {
  background-color: var(--color-background-white);
  padding: 8px;
  border-radius: var(--border-radius-default);
  box-shadow: var(--shadow-main-default);
  display: flex;
  gap: 8px;
  align-items: center;

  &Box {
    width: 16px;
    height: 16px;
    border-radius: 2px;
  }

  &Text {
    color: var(--color-grayish);
    font-size: var(--font-size-xsm);
    font-weight: var(--font-weight-medium);

    &Highlight {
      font-weight: var(--font-weight-bold);
    }
  }
}
