.chartHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  &Title {
    font-size: var(--font-size-4xl);
  }

  &Info {
    background-color: var(--color-neutral-light-500);
    color: var(--color-grayish);
    font-size: var(--font-size-xsm);
    margin-top: 10px;
    padding: 4px 8px;
    border-radius: var(--border-radius-input);
    width: max-content;
    display: flex;
  }

  p {
    margin: 0
  }
}

.fields {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  margin-left: 60px;
}

.field {
  width: 300px;

  &Label {
    color: var(--color-grayish);
    font-size: var(--font-size-xsm);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-default);
    letter-spacing: var(--letter-space-default);
    text-transform: uppercase;
  }

  &Wrapper, &Loading {
    position: relative;
    margin-top: 5px;
  }

  &Icon {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.multipleStations {
    width: auto;
    max-width: 750px;
    flex: 1;

    span:nth-child(2) {
      display: block;
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  box-shadow: var(--shadow-card-default);
  border-radius: var(--border-radius-default);
  background-color: var(--color-neutral-light-100);
  margin-bottom: 30px;
}

.legend {
  display: flex;
  gap: 23px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  align-items: center;
  margin-top: 40px;
  flex-wrap: wrap;

  &Farms {
    display: flex;
    gap: 10px 23px;
  }

  &Item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
  }

  &Label {
    display: flex;
    align-items: center;
    gap: 8px;

    &Text {
      font-size: var(--font-size-normal);
      font-weight: var(--font-weight-medium);
      line-height: var(--line-height-default);
      color: var(--color-grayish);
      text-align: center;

      &Info {
        display: block;
        color: var(--color-grayish-light);
      }
    }

    &Value {
      color: var(--color-grayish);
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-semibold);
      line-height: var(--font-size-4xl);
    }
  }

  &Icon {
    width: 24px;
    height: 24px;
    border-radius: 2px;

    &Green {
      background-color: #A2D673;
    }

    &Blue {
      background-color: #61A1C6;
    }
  }
}

.tooltip {
  background-color: var(--color-background-white);
  padding: 16px;
  border-radius: var(--border-radius-default);
  box-shadow: var(--shadow-main-default);
  display: flex;
  gap: 8px;
  flex-direction: column;
  background: #fefffe;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(0,0,0,.1) !important;

  p {
    border-bottom: 1px solid var(--color-neutral-100);
    color: var(--color-grayish);
    font-size: var(--font-size-normal);
    margin: 0 0 8px;
    padding-bottom: 8px;
  }

  &Divider {
    border-top-color: var(--color-neutral-200);
    width: 100%;
    padding: 0;
    margin: 8px 0;
  }

  &Row {
    display: flex;
    flex-direction: row;
    height: 40px;
    gap: 8px;
  }

  &Box {
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
    border-radius: 16px;
  }

  &TextContainer {
    display: flex;
    flex-direction: column;
  }

  &Text {
    color: var(--color-grayish);
    font-size: var(--font-size-xsm);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-default);

    &Highlight {
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-lg);
      line-height: var(--line-height-2xl);
    }
  }
}

:global(.recharts-cartesian-axis-tick-value) {
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  font-size: var(--font-size-normal);
}

:global(.recharts-legend-wrapper span) {
  text-transform: none !important;
}

:global(.recharts-precipitation .recharts-label-list tspan) {
  paint-order: stroke;
  stroke: #fff;
  stroke-width: 5px;
}

@keyframes movingLeft {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-2em);
  }
}

@keyframes upAndDown {
  0%,
  100% {
    transform: translateY(-0.5em);
  }
  25%,
  75% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0.5em);
  }
}

.loadingContainer {
  background: var(--color-neutral-light-200);
  height: 450px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px dashed var(--color-neutral-200);
  align-items: center;
  justify-content: center;

  &Text {
    color: var(--color-grayish);
  }
}

.loading {
  font-size: 26px;
  width: 240px;
  height: 160px;
  position: relative;

  &Frame {
    background: #fff;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-neutral-100);
    border-radius: var(--border-radius-default);
    position: relative;
    box-shadow: 0 0 0.85em rgba(0, 0, 0, 0.05);

    &:before {
      content: "";
      width: 200%;
      height: 2em;
      overflow: hidden;
      top: 50%;
      position: absolute;
      margin-top: -1.5em;
      display: block;
      background-image: linear-gradient(
        45deg,
        transparent 50%,
        var(--color-primary-200) 50%,
        transparent calc(50% + 1px)
      ),
        linear-gradient(
          135deg,
          transparent 50%,
          var(--color-primary-200) 50%,
          transparent calc(50% + 1px)
        );
      background-size: 2em 4em, 2em 4em;
      background-position: -1em 0, 3em 0;
      background-repeat: repeat-x;
      animation: movingLeft 1.5s linear infinite;
    }
  }

  &Circle {
    content: "";
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    background-color: var(--color-primary-200);
    position: absolute;
    top: 50%;
    border: 2px solid white;
    margin-top: -0.25em;
    margin-right: -0.25em;
    right: 0;
    animation: upAndDown 1.5s linear infinite;
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.25);
  }
}

.emptyDataMessage {
  fill: var(--color-neutral-500);
  font-size: 18px;
}