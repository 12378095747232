.treeView {
  margin: 0;
  padding: 0;

  ol {
    margin: 8px 0 0 24px;
  }

  li {
    color: var(--color-grayish-dark);
    list-style: none;
    font-size: var(--font-size-normal);
    letter-spacing: var(--letter-space-xs);
    margin-bottom: 8px;
  }

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;

    &:hover,
    &.selected {
      color: var(--color-primary);
    }

    &:hover .itemCode,
    &.selected .itemCode {
      background: var(--color-primary-200);
      color: var(--color-background-white);
    }
  }

  i {
    color: var(--color-grayish);
  }

  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    cursor: pointer;

    &::before {
      font-family: var(--font-icon);
      font-size: var(--font-size-xl);
      color: var(--color-grayish);
      content: '\e912';
    }

    &:checked::before {
      color: var(--color-primary-200);
      content: '\e913';
    }

    &:disabled::before {
      opacity: 0.2;
    }
  }

  .itemCode {
    background: var(--color-neutral-light-300);
    border-radius: var(--border-radius-input);
    color: var(--color-grayish);
    font-size: 85%;
    padding: 2px 4px;
    margin-left: 2px;
    transition: all 0.1s linear;
  }
}

.emptyList {
  color: var(--color-grayish);
  font-size: var(--font-size-normal);
  margin: 0 0 8px;
}

.searchInput {
  margin-bottom: 16px;
  height: auto;
  padding: 8px;
}

.errorMessage {
  color: var(--color-danger);
  font-size: var(--font-size-xsm);
  font-weight: var(--font-weight-semibold);
}
