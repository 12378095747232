.chip {
  display: inline-flex;
  background-color: var(--color-primary-200);
  color: var(--color-neutral-light-200);
  border-radius: var(--border-radius-chip);
  color: var(--color-neutral-light-200);
  box-sizing: border-box;
  line-height: var(--line-height-sm);
  text-align: center;
  align-items: center;
  max-width: max-content;
  max-height: 1.5rem;
  width: fit-content;
  padding: 1rem 0.75rem;
  white-space: nowrap !important;

  &.disabled {
    pointer-events: none;
  }

  .chipLabel {
    color: var(--color-neutral-light-200);
  }
}

.basic {
  &:hover {
    background-color: var(--color-primary-500);
  }

  &.disabled {
    background-color: var(--color-grayish-light);
  }
}

.interactive {
  cursor: pointer;
  border: var(--border-primary-light);

  .unselected {
    background-color: transparent;
    color: var(--color-primary-200);

    .chipLabel {
      color: var(--color-primary-200);
    }

    &:hover {
      background-color: transparent;
      border: var(--border-primary);
      color: var(--color-primary);

      .chipLabel {
        color: var(--color-primary);
      }
    }

    &.disabled {
      border: var(--border-grayish-light);
      color: var(--color-grayish-light);

      .chipLabel {
        color: var(--color-grayish-light);
      }
    }
  }
}

.unselected {
  background-color: transparent;
  border: var(--border-neutral);
  color: var(--color-grayish);

  .chipLabel {
    color: var(--color-grayish);
  }

  &:hover {
    background-color: transparent;
  }

  &.disabled {
    border: var(--border-grayish-light);
    color: var(--color-grayish-light);

    .chipLabel {
      color: var(--color-grayish-light);
    }
  }
}

.right {
  flex-direction: row-reverse;

  .icon {
    padding-left: 5px;
    padding-right: 0;
  }
}

.icon {
  display: flex;
  padding-left: 0;
  padding-right: 5px;
}

.statusActive {
  background-color: var(--color-success);
}

.statusInactive {
  background-color: var(--color-neutral-500);
}

.speciesBovine {
  background-color: var(--color-info);
}

.speciesEquine {
  background-color: var(--color-warning);
}

.speciesBubaline {
  background-color: var(--color-grayish);
}

.sexFemale {
  background-color: var(--color-danger-light);
}

.sexMale {
  background-color: var(--color-info);
}

.cost {
  background-color: var(--color-info);
}

.invest {
  background-color: var(--color-success);
}

.shutdownSale {
  background-color: var(--color-primary-50);
  border: var(--border-primary);

  .chipLabel {
    color: var(--color-primary);
  }
}

.shutdownDeath,
.shutdownOther {
  background-color: var(--color-grayish-light);
  border: var(--border-secondary);
}

.categoryGrowing {
  background-color: var(--color-primary-30);
  border: var(--border-secondary);

  .chipLabel {
    color: var(--color-primary);
  }
}

.categorySteer {
  background-color: var(--color-primary-50);
  border: var(--border-secondary);

  .chipLabel {
    color: var(--color-primary);
  }
}

.categoryHeifer {
  background-color: var(--color-primary-100);
  border: var(--border-primary);

  .chipLabel {
    color: var(--color-primary);
  }
}

.categoryBreedingBull {
  background-color: var(--color-primary);
}

.categoryCow {
  background-color: var(--color-primary-900);
}

.categoryYearlingBull {
  background-color: var(--color-grayish-light);
}

.breedPurebred {
  background-color: var(--color-primary-200);
}

.breedMixed {
  background-color: var(--color-warning);
}

.breedComposite {
  background-color: var(--color-grayish);
}

.cowPregnant {
  background-color: var(--color-primary-200);
}

.cowOpen {
  background-color: var(--color-grayish-light);
}

.cowInseminated {
  background-color: transparent;
  border: var(--border-neutral);

  .chipLabel {
    color: var(--color-grayish);
  }
}

.suitableTrue,
.offspringTrue,
.herdTrue,
.associationTrue {
  background-color: var(--color-primary-200);
}

.suitableFalse,
.herdFalse,
.associationFalse,
.offspringFalse {
  background-color: var(--color-neutral-light-100);
  border: var(--border-danger-secondary-light);

  .chipLabel {
    color: var(--color-danger);
  }
}

.defaultTrue,
.suitableTrue,
.offspringTrue,
.herdTrue,
.associationTrue .defaultTrue,
.suitableFalse,
.herdFalse,
.associationFalse,
.offspringFalse,
.defaultFalse {
  min-width: 90px;
  justify-content: center;
}

.defaultFalse {
  background-color: transparent;
  border: var(--border-primary-light);

  .chipLabel {
    color: var(--color-grayish);
  }
}

.secondary {
  background-color: var(--color-primary-100);
}
