@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: local('Inter'),
    url(../../assets/fonts/Inter-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: local('Inter'),
    url(../../assets/fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: local('Inter'),
    url(../../assets/fonts/Inter-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: local('Inter'),
    url(../../assets/fonts/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: local('Inter'), url(../../assets/fonts/Inter-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  src: local('Inter'),
    url(../../assets/fonts/Inter-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  src: local('Inter'),
    url(../../assets/fonts/Inter-Black.ttf) format('truetype');
}
