.basicInfo {
  display: flex;
  justify-content: space-between;
  padding: var(--padding-card-default);
  box-shadow: var(--shadow-card-default);
  border-radius: var(--border-radius-default);
  background-color: var(--color-neutral-light-100);
  margin-bottom: 1.5em;

  & > * {
    display: flex;
    flex-direction: column;
  }

  & > div {
    gap: 0.75em;
  }
}
.basicInfo {
  p {
    font-weight: var(--font-weight-semibold);
    color: var(--color-grayish);
    margin: 0;
  }
}

.empty {
  color: var(--color-neutral-200) !important;
}
