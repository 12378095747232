.checkboxButtonContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 18px;
}

.checkboxButtonWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.checkboxButtonLabel {
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-default);
  color: var(--color-grayish);
  transition: color 0.3s ease;
  cursor: pointer;
}

.checkboxButton[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;
  cursor: pointer;

  &::before {
    font-family: var(--font-icon);
    font-size: var(--font-size-4xl);
    color: var(--color-grayish);
    content: '\e91f';
  }

  &:checked::before {
    color: var(--color-primary);
    content: '\e920';
  }

  &:disabled::before {
    color: var(--color-neutral-500);
    content: '\e91f';
  }

  &:disabled + .checkboxValue {
    color: var(--color-neutral-500);
  }
}

.chipButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;

  .checkboxButtonWrapper {
    width: 90%;
    gap: 0.5em;
  }

  .checkboxButtonLabel {
    flex-basis: auto;
  }

  .checkboxValue {
    width: fit-content;
    display: inline-flex;
    background-color: transparent;
    border: var(--border-primary-light);
    color: var(--color-grayish);
    border-radius: var(--border-radius-chip);
    font-size: var(--font-size-normal);
    line-height: var(--line-height-sm);
    box-sizing: border-box;
    text-align: center;
    align-items: center;
    max-height: 1.5rem;
    padding: 1rem 0.75rem;

    &:hover {
      opacity: 0.8;
    }
  }

  .checkboxButton[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    cursor: pointer;

    &::before {
      display: none;
    }

    &:checked + .checkboxValue {
      background-color: var(--color-primary-200);
      color: var(--color-neutral-light-200);
    }
  }
}
