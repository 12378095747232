.boxedTableWrapper {
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-card-default);
  border-radius: var(--border-radius-default);
  background-color: var(--color-neutral-light-100);
  margin-bottom: 1.5em;

  form,
  form > * {
    height: auto !important;
    min-height: auto !important;
    margin: 0 !important;
  }

  tbody {
    tr,
    td {
      border-radius: 0 !important;
    }

    tr:last-child {
      td:first-child {
        border-bottom-left-radius: var(--border-radius-default) !important;
      }
      td:last-child {
        border-bottom-right-radius: var(--border-radius-default) !important;
      }
    }
  }
}

.boxedTablePaginated {
  tbody {
    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 0 !important;
      }
      td:last-child {
        border-bottom-right-radius: 0 !important;
      }
    }
  }
}

.boxedTableTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3em;
  padding: var(--padding-card-default);
  padding-bottom: 1.5em;

  & > * {
    display: flex;
    align-items: center;
    gap: 1.5em;
  }
}

.sticky {
  .boxedTableTitle {
    position: sticky;
    background-color: var(--color-neutral-light-100);
    border-radius: var(--border-radius-default);
    top: var(--boxed-layout-sticky-scrolling-space);
    z-index: 99;
  }

  th {
    position: sticky;
    background-color: var(--color-neutral-light-100);
    top: var(--boxed-table-head-top-default);
    z-index: 99;
  }
}

.quantityBadge {
  display: flex;
  background-color: var(--color-primary-50);
  padding: 0.5em 1em;
  border-radius: var(--border-radius-default);

  * {
    color: var(--color-primary);
  }

  :first-child {
    font-weight: var(--font-weight-bold) !important;
    padding-right: 0.5em;
  }
}

.boxedTableEmpty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 4rem 0;

  * {
    position: absolute;
  }
}

.caption3 {
  font-family: var(--font-family);
  color: var(--default-font-color);
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-normal);
  letter-spacing: var(--letter-space-default);
}

.paginationContainer {
  position: sticky;
  bottom: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.25em;
  border-top: var(--border-table-hairline);
  background: var(--color-neutral-light-100);
  border-radius: var(--border-radius-default);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
