.input {
  display: flex;
  align-items: center;
  gap: 0.5em;

  input {
    min-width: 200px !important;
  }

  input[type='date'] {
    color: var(--color-grayish-dark);
  }
}

.searchAnimal {
  position: relative;
  width: 100%;
  min-width: 290px;

  &AutoComplete {
    border-right: 1px solid var(--color-neutral-200) !important;
    border-radius: var(--border-radius-input) !important;
    height: 50px !important;

    &Linked {
      border-color: var(--color-success) !important;
    }
  }

  &LinkedIcon {
    color: var(--color-success);
    position: absolute;
    top: 12px;
    right: 12px;
  }
}