.expandedSection {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.emptyMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2em;
}
