.expandedSection {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.expandedValues {
  display: flex;
  gap: 0.25em;
}
