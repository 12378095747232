.button {
  display: inline-flex;
  border: 0;
  border-radius: var(--border-radius-default);
  color: var(--color-neutral-light-200);
  font-size: var(--font-size-lg);
  line-height: var(--line-height-sm);
  font-weight: var(--font-weight-semibold);
  text-transform: uppercase;
  text-align: center;
  align-items: center;
  max-height: 3em;
  cursor: pointer;
  position: relative;

  &:disabled {
    cursor: not-allowed;
  }

  .iconWrapper svg path {
    fill: var(--color-neutral-light-200);
  }

  .subLabel {
    display: block;
    color: #999;
    font-size: var(--font-size-xsm);
    font-weight: var(--font-weight-medium);
  }
}

.primary {
  background-color: var(--color-primary);

  &:hover {
    background-color: var(--color-primary-900);
  }

  &:active {
    background-color: var(--color-primary-200);
  }

  &:disabled {
    background-color: var(--color-grayish-light);

    .loadingWrapper span {
      border-top-color: var(--color-neutral-dark);
    }
  }
}

.outline {
  background-color: transparent;
  border: var(--border-primary);
  color: var(--color-primary);

  .iconWrapper svg path {
    fill: var(--color-primary);
  }

  &:hover {
    border: var(--border-primary-dark);
    color: var(--color-primary-900);

    .iconWrapper svg path {
      fill: var(--color-primary-900);
    }
  }

  &:active {
    border: var(--border-primary-light);
    color: var(--color-primary-200);

    .iconWrapper svg path {
      fill: var(--color-primary-200);
    }
  }

  &:disabled {
    border: var(--border-grayish-light);
    color: var(--color-grayish-light);

    .loadingWrapper span {
      border-top-color: var(--color-neutral-dark);
    }

    .iconWrapper svg path {
      fill: var(--color-grayish-light);
    }
  }
}

.ghost {
  color: var(--color-primary);
  background-color: transparent;

  .iconWrapper svg path {
    fill: var(--color-primary);
  }

  &:hover {
    color: var(--color-primary);
    background-color: var(--color-neutral-200);

    .iconWrapper svg path {
      fill: var(--color-primary);
    }
  }

  &:active {
    color: var(--color-primary-200);

    .iconWrapper svg path {
      fill: var(--color-primary-200);
    }
  }

  &:disabled {
    color: var(--color-grayish-light);

    .iconWrapper svg path {
      fill: var(--color-grayish-light);
    }

    .loadingWrapper span {
      border-top-color: var(--color-neutral-dark);
    }
  }
}

.destructive {
  background-color: var(--color-danger);

  &:hover {
    background-color: var(--color-danger-dark);
  }

  &:active {
    background-color: var(--color-danger-light);
  }

  &:disabled {
    background-color: var(--color-grayish-light);

    .loadingWrapper span {
      border-top-color: var(--color-neutral-dark);
    }
  }
}

.loadingWrapper,
.iconWrapper {
  display: flex;
  padding-left: 2px;
  padding-right: 18px;

  & > img,
  svg {
    width: 15px;
    height: 15px;
  }
}

.loadingWrapper {
  padding-right: 10px;
}

.left {
  flex-direction: row;
}

.right {
  flex-direction: row-reverse;

  .iconWrapper {
    padding-left: 18px;
    padding-right: 2px;
  }

  .loadingWrapper {
    padding-left: 10px;
    padding-right: 2px;
  }
}

.small {
  padding: 0.3em 0.5em;
  text-transform: none;

  .iconWrapper {
    padding-left: 2px;
    padding-right: 8px;
  }

  &.right {
    .iconWrapper {
      padding-left: 8px;
      padding-right: 2px;
    }
  }
}

.medium {
  padding: 1em;
}

.large {
  padding: 1em;
  min-width: 230px;
  justify-content: center;
}

.tooltip {
  &::before {
    content: attr(data-hover);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 110%;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    width: 160px;
    padding: 0.5em 1em;
    white-space: normal;
    overflow: hidden;
    text-align: center;
    text-transform: none;
    background-color: var(--color-primary-30);
    color: var(--color-primary);
    font-size: var(--font-size-xsm);
    border-radius: var(--border-radius-input);
    box-shadow: var(--shadow-main-default);
    transition: opacity 0.5s ease-in-out;
  }

  &:hover:before {
    opacity: 1;
    visibility: visible;
  }

  &Left {
    &::before {
      left: -200px;
      transform: translateY(-50%);
      top: 50%;
    }
  }

  &Right {
    &::before {
      right: -200px;
      transform: translateY(-50%);
      top: 50%;
    }
  }
}

.loading {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.loadingSpinner {
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-top: 3px solid var(--color-primary);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
