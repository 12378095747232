.container {
  display: flex;
  flex-direction: column;
}

.label {
  margin: 6px 4px 12px;
}

.helperText {
  margin: 6px 4px;
  font-size: var(--font-size-xsm);
  font-weight: var(--font-weight-semibold);
  color: var(--color-grayish);
}

.error {
  color: var(--color-danger);
}

.success {
  color: var(--color-success);
}

.dropdownIndicator {
  display: none !important;
}
