.genealogyParent {
  display: flex;
  align-items: center;
}

.genealogyParentClickable {
  cursor: pointer;

  &:hover > div {
    border-color: var(--color-primary);
  }
}

.genealogyCard {
  border: var(--border-separator);
  padding: 1em;
  box-shadow: var(--shadow-card-default);
  border-radius: var(--border-radius-default);
  background-color: var(--color-neutral-light-100);
  height: 100px;
  flex: 1;
  box-sizing: border-box;
}

.genealogyCardEmpty {
  background: var(--color-neutral-100);
}

.genealogyCardImported {
  background: var(--color-neutral-light-200);
}

.empty {
  color: var(--color-grayish-light);
  font-size: var(--font-size-lg);
  display: block;
  margin-top: 0.5em;
}

.animalInfo {
  display: flex;
  gap: 3em;
  margin-top: 0.5em;

  caption {
    text-align: left;
    white-space: nowrap;
  }
}