.card {
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  box-shadow: var(--shadow-card-default);
  border-radius: var(--border-radius-default);
  background-color: var(--color-neutral-light-100);
  margin-bottom: 1.5em;

  &Title {
    font-size: var(--font-size-4xl);
  }

  &Header {
    margin-left: 32px;
  }
}

.totalValue {
  color: var(--color-grayish);
  font-size: var(--font-size-7xl);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-10xl);
  margin-bottom: 40px;
}

.legend {
  display: flex;
  gap: 23px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  align-items: center;
  margin-top: 40px;

  &Farms {
    display: flex;
    gap: 10px 23px;
    flex-wrap: wrap;
  }

  &Item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  &Label {
    display: flex;
    align-items: center;
    gap: 8px;

    &Text {
      font-size: var(--font-size-normal);
      font-weight: var(--font-weight-medium);
      line-height: var(--line-height-default);
      color: var(--color-grayish);
      text-transform: uppercase;
    }

    &Value {
      color: var(--color-grayish);
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-semibold);
      line-height: var(--font-size-4xl);
    }
  }

  &Icon {
    width: 24px;
    height: 24px;
    border-radius: 2px;

    &Total {
      background-color: var(--color-grayish);
    }
  }
}

.xAxis {
  fill: var(--color-primary);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  font-size: var(--font-size-normal);
}

.yAxis {
  fill: var(--color-grayish);
  font-size: var(--font-size-normal);
  font-weight: var(--font-weight-medium);

  &Label {
    fill: var(--color-grayish);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-normal);
  }
}

.tooltip {
  background-color: var(--color-background-white);
  padding: 8px;
  border-radius: var(--border-radius-default);
  box-shadow: var(--shadow-main-default);
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: flex-start;

  &Row {
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &Box {
    width: 16px;
    height: 16px;
    border-radius: 2px;
  }

  &Label {
    color: var(--color-grayish);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
  }

  &Text {
    color: var(--color-grayish);
    font-size: var(--font-size-xsm);
    font-weight: var(--font-weight-medium);

    &Highlight {
      font-weight: var(--font-weight-bold);
    }
  }
}
