.importMessage {
  p {
    margin-top: 0;
  }

  form {
    text-align: left;
  }

  &Textarea {
    margin-bottom: 24px;
  }
}
