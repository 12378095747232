.radioButtonContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 18px;
}

.radioButtonWrapper {
  display: flex;
  gap: 30px;
}

.radioButtonLabel {
  display: flex;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-default);
  color: var(--color-grayish);
  transition: color 0.3s ease;
  cursor: pointer;

  &Disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: var(--color-neutral-200);
  }
}

.radioButton[type='radio'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  cursor: pointer;

  &::before {
    font-family: var(--font-icon);
    margin-right: 14px;
    font-size: var(--font-size-xl);
    color: var(--color-grayish);
    content: '\e912';
  }

  &:checked::before {
    color: var(--color-primary-200);
    content: '\e913';
  }

  &:disabled::before {
    opacity: 0.2;
  }

}

.chipButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .radioButtonWrapper {
    gap: 18px;
  }

  .radioValue {
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: transparent;
    border: var(--border-primary-light);
    color: var(--color-grayish);
    font-size: var(--font-size-normal);
    box-sizing: border-box;
    text-align: center;
    align-items: center;
    max-height: 1.5rem;
    border-radius: var(--border-radius-chip);
    padding: 1rem 0.75rem;

    &:hover {
      opacity: 0.8;
    }
  }

  .buttonValue {
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: transparent;
    border: var(--border-primary-light);
    color: var(--color-grayish);
    font-size: var(--font-size-normal);
    box-sizing: border-box;
    text-align: center;
    align-items: center;
    max-height: 48px;
    border-radius: 4px;
    padding: 1rem 0.75rem;

    &:hover {
      color: var(--color-primary-200);
      background-color: var(--color-primary-30);
    }

    .subLabel {
      display: block;
      color: #999;
      font-size: var(--font-size-xsm);
      font-weight: var(--font-weight-medium);
    }
  }

  .radioButton[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    cursor: pointer;

    &::before {
      display: none;
    }

    &:checked+.radioValue {
      background-color: var(--color-primary-200);
      color: var(--color-neutral-light-200);
    }

    &:disabled+.radioValue {
      background-color: var(--color-neutral-light-500);
      border-color: var(--color-neutral-100);
      color: var(--color-neutral-200);
    }

    &:checked+.buttonValue {
      background-color: var(--color-primary-200);
      color: var(--color-neutral-light-200);

      .subLabel {
        background-color: var(--color-primary-200);
        color: var(--color-neutral-light-200);
      }
    }

    &:disabled+.buttonValue {
      background-color: var(--color-neutral-light-500);
      border-color: var(--color-neutral-100);
      color: var(--color-neutral-200);
    }
  }

  .radioButtonLabel {
    flex: 1
  }
}