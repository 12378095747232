.formSection {
  margin-bottom: 1em;
}

.formSectionTitle {
  display: flex;
  color: var(--color-primary-200);
  border-bottom: var(--border-primary-lighter);
  padding-bottom: 0.5em;
  margin-bottom: 2em;
}

.formRow {
  display: flex;
  flex-wrap: wrap;
  gap: 45px;
  margin-bottom: 50px;
}

.formCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 320px;
}

.alignRight {
  align-self: flex-end;
}

.formRow > * {
  max-width: var(--input-width-default);
}

.formButtonContainer {
  display: flex;
  flex-direction: row-reverse;
  align-self: flex-end;
  gap: 1.5em;
  margin-top: auto;
  padding-bottom: 3em;
}

.drawerTitle {
  hr {
    padding: 0;
  }
}

.drawerRow {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5em;
  margin-bottom: 1em;
}

.drawerRowSingleItem {
  grid-template-columns: 1fr;
}

.drawerCol {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5em;
  min-width: 0;

  & > * {
    flex: 1;
  }

  [class*='helperText'] {
    position: absolute;
    bottom: -40px;
  }
}

.drawerColColumn {
  display: block;

  & > div {
    display: flex;
    gap: 0.5em;

    & > * {
      flex: 1;
      min-width: 0;

      input[type='date'] {
        min-width: 0 !important;
      }
    }
  }

  & label {
    margin: 0;
  }

  & > label {
    display: block;
    color: var(--color-grayish);
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-default);
    font-size: var(--font-size-normal);
    margin: 6px 4px;
  }
}

.drawerButtonContainer {
  position: absolute;
  display: block;
  width: -webkit-fill-available;
  padding-right: 1.5em;
  bottom: 4em;

  button {
    width: -webkit-fill-available;
    max-width: 370px;
  }
}
