.dialogContainer {
  margin: 0;
  border: 0;
  padding: 0;
  display: flex;
  width: fit-content;
  background-color: var(--color-neutral-light-200);
  box-shadow: var(--shadow-main-default);
  border-radius: var(--border-radius-input);
}

.dialogItem {
  display: flex;
  align-items: center;
  padding: 0.5em 0.75em;
  border-radius: var(--border-radius-input);
  cursor: pointer;

  &:hover {
    background-color: var(--color-neutral-light-500);
  }
}

.dialogItemIcon {
  margin-right: 0.5em;
  color: var(--color-grayish);
}

.dialogItemText {
  color: var(--color-grayish);
}

.closed {
  display: none;
}

.open {
  display: block;
}
