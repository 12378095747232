.fields {
  display: flex;
  margin-bottom: 24px;
}

.field {
  margin-left: auto;

  &Label {
    color: var(--color-grayish);
    font-size: var(--font-size-xsm);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-default);
    letter-spacing: var(--letter-space-default);
    text-transform: uppercase;
  }

  &Wrapper {
    position: relative;
    margin-top: 8px;
  }

  &Icon {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
}
