.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5em;
  position: relative;
  z-index: 99;
}

.counter {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.caption3 {
  font-family: var(--font-family);
  color: var(--default-font-color);
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-normal);
  letter-spacing: var(--letter-space-default);
}

.activeItems {
  background: var(--color-primary-50);

  &:hover {
    background: var(--color-primary-100);

    & > span:last-of-type {
      background: var(--color-primary-500);
    }
  }

  & > span:last-of-type {
    background: var(--color-primary-200);
    color: var(--color-neutral-light-300);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 25px;
    padding: 0;
  }
}