.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-header-default);
  box-shadow: var(--shadow-header-default);
  background-color: var(--color-neutral-light-100);
  margin-bottom: 1.5em;
}

.statusContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2em;
}

.status {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.shutdownDate {
  margin-right: 2em;
}

.identifiers {
  display: flex;
  align-items: center;
  gap: 2em;
}

.name {
  display: flex;
  max-width: 230px;
}

.numbersWrapper {
  display: flex;
  align-items: center;
  gap: 1.5em;
}

.stockNumber,
.earTagNumber {
  overflow: visible;
  white-space: nowrap;
}

.numbersSeparator {
  display: block;
  min-width: 7px;
  height: 7px;
  background-color: var(--color-grayish-200);
  border-radius: var(--border-radius-default);
  opacity: 0.5;
  margin-top: 1em;
}

.empty {
  color: var(--color-neutral-200);
}
