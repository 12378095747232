.genealogyTree {
  display: grid;
  margin-bottom: 1em;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
  grid-template-areas:
    'father_great_grandfather  father_grandfather  .'
     '.  father_grandfather  father'     
     '.  .  father'     
     '.  .  mother'
     '.  mother_grandfather  mother'
     'mother_great_grandfather mother_grandfather .'
}