.search {
  background: var(--color-neutral-100);
  margin: var(--boxed-layout-top-negative-margin) -2em 3em;
  padding: 2em;

  form {
    display: flex;
    height: auto !important;
    flex-direction: row;
    align-items: flex-start;
    gap: 1em;

    & > * {
      flex: 1
    }
  }
}

.searchColumn {
  max-width: 260px;
}

.searchSelect {
  & > div {
    background: var(--color-background-white);
  }
}

.searchInput {
  & div > span > div {
    background: var(--color-background-white);
  }
}

.searchSubmit {
  max-width: 230px;
  padding-top: 32px;
}