.orderCell {
  color: var(--color-neutral-500);
}

.infoHeader {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.infoHeaderIcon {
  color: var(--color-neutral-500);
}
