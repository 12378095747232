.labeledCardWrapper {
  display: flex;
  flex-direction: column;
  padding: var(--padding-card-default);
  box-shadow: var(--shadow-card-default);
  border-radius: var(--border-radius-default);
  background-color: var(--color-neutral-light-100);
  margin-bottom: 1.5em;
  padding-bottom: 1.5em;

  form,
  form > * {
    height: auto !important;
    min-height: auto !important;
    margin: 0 !important;
  }
}

.labeledCardTitle {
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  border-bottom: var(--border-neutral-light);
}

.labeledCardRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1em;
}

.labeledCardDateRow {
  display: flex;
  align-items: flex-end;
  gap: 1em;
}

.labeledCardButtonRow {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  padding-top: 1em;
}

.labeledCardCol {
  display: flex;
  flex-direction: column;
}
