.filterFarm {
  box-shadow: var(--shadow-card-default);
  border-radius: var(--border-radius-default);
  background-color: var(--color-neutral-light-100);
  margin-bottom: 1.5em;
  padding: 1em 2em 1.5em;

  &Divider {
    padding-bottom: 0.5em;
    margin-bottom: 0;
  }

  > form {
    display: flex;
    gap: 2em;
    height: auto;
    flex-direction: row;

    & > div {
      width: 33.3%;
    }
  }
}

.selectedItem {
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  &:not(:last-of-type) {
    margin-bottom: 1em;
  }
}

.reviewFarm {
  display: flex;
  gap: 2em;
  height: auto;
  flex-direction: row;
  box-shadow: var(--shadow-card-default);
  border-radius: var(--border-radius-default);
  background-color: var(--color-neutral-light-100);
  margin-bottom: 1.5em;
  padding: 1em 2em 1.5em;

  &Divider {
    padding-bottom: 0.5em;
    margin-bottom: 0;
  }

  & > div {
    width: 33.3%;
  }
}
