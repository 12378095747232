.link {
  font-size: var(--font-size-lg);
  color: var(--color-neutral-900);
  cursor: pointer;
  font-weight: var(--font-weight-normal);

  &:hover {
    color: var(--color-primary-100);
  }

  &:active {
    color: var(--color-grayish);
  }
}

.none {
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

.fs-xsm {
  font-size: var(--font-size-xsm);
}

.fs-normal {
  font-size: var(--font-size-normal);
}

.fs-lg {
  font-size: var(--font-size-lg);
}
