.page {
  display: flex;
  min-height: 100vh;
  height: 100%;
  background-color: var(--color-background);
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: var(--menu-width-default);
  z-index: 99;

  form {
    height: 100vh;
  }
}

.mainLimitedWidth {
  max-width: calc(100vw - var(--menu-width-default-margin-adjust));
}

.headerContainer,
.footer {
  display: flex;
  z-index: 100;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  gap: 1em;
  width: -webkit-fill-available;
  padding: var(--padding-boxed-header-default);
  background-color: var(--color-neutral-light-200);
  box-shadow: var(--shadow-header-default);
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
}

.headerText {
  color: var(--color-primary);
}

.headerInfoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3em;
}

.headerDate {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.headerDateTitle {
  font-family: var(--font-family);
  color: var(--default-font-color);
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);
  white-space: nowrap;
  font-size: var(--font-size-normal);
  letter-spacing: var(--letter-space-default);
}

.dateEdit {
  cursor: pointer;

  &::after {
    opacity: 0;
    font-family: var(--font-icon);
    font-size: var(--font-size-xl);
    color: var(--color-grayish);
    content: '\e918';
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }
}

.highlightBadge,
.quantityBadge {
  background-color: var(--color-primary-50);
  padding: 0.5em 1em;
  border-radius: var(--border-radius-default);

  * {
    color: var(--color-primary);
  }

  :first-child {
    font-weight: var(--font-weight-bold) !important;
  }
}

.highlightBadge {
  * {
    text-transform: none !important;
  }
}

.highlightLabel {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.quantityBadge {
  :first-child {
    padding-right: 0.5em;
  }
}

.contentWrapper {
  margin: var(--boxed-layout-top-height) 2em 4em;
  min-width: calc(100vw - var(--menu-width-default-margin-adjust));
}

.contentWrapperWithFooter {
  margin: var(--boxed-layout-top-height) 2em 5em;
  min-width: calc(100vw - var(--menu-width-default-margin-adjust));
}

.stickyScrollingSpace {
  z-index: 99;
  position: fixed;
  top: 0;
  width: 100%;
  margin-left: -2em;
  height: var(--boxed-layout-sticky-scrolling-space);
  background-color: var(--color-background);
}

.headerActionWrapper {
  display: flex;
  align-items: center;
  gap: 2em;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  gap: 1em;
  width: -webkit-fill-available;
  padding: var(--padding-boxed-footer-default);
  background-color: var(--color-neutral-light-200);
  box-shadow: var(--shadow-footer-default);
  box-sizing: border-box;
}

.currentPageItems {
  display: flex;
  align-items: center;
  gap: 0.25em;
}

.caption3 {
  font-family: var(--font-family);
  color: var(--default-font-color);
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-normal);
  letter-spacing: var(--letter-space-default);
}