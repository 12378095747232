.customExpandCard {
  justify-content: flex-start;

  & > * {
    flex: 0;
  }
}

.bold {
  font-weight: var(--font-weight-semibold);
}

.radioButton {
  font-size: var(--font-size-normal);
  color: var(--color-grayish-dark);
}

.chip {
  min-height: 32px;
}

.farmSelect {
  display: flex;
  flex-direction: column;

  & .selectsTitle {
    font-family: var(--font-family);
    color: var(--default-font-color);
    text-transform: uppercase;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-normal);
    letter-spacing: var(--letter-space-default);
    margin: 32px 0 8px 0;
    color: var(--color-primary);
  }
}

.chipsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
