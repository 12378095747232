.drawerContainer {
  padding: 1.5em;
  max-width: 1100px;
  min-width: 960px;
}

.scrollableDrawer {
  form {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
  }
}

.drawerClose {
  display: flex;
  justify-content: flex-end;
  color: var(--color-grayish);
}

.drawerTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.25em 0;
}
