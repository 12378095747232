.review {
  overflow-x: auto;
  min-height: 450px;

  table {
    border: 1px solid var(--color-neutral-100);
    border-radius: var(--border-radius-default);

    & + div {
      background: var(--color-background-white);
      border-radius: 0 0 var(--border-radius-default)
        var(--border-radius-default);
    }
  }

  thead th {
    background: var(--color-background-white);
    text-transform: none;
    font-weight: var(--font-weight-semibold);
    letter-spacing: 0;
    padding: 24px 12px !important;
  }

  thead th:first-child {
    border-top-left-radius: var(--border-radius-default);
  }

  thead th:last-child {
    border-top-right-radius: var(--border-radius-default);
  }

  tbody tr td:first-child,
  tbody tr td:last-child {
    border-radius: 0;
  }

  tbody tr:last-child td:first-child {
    border-bottom-left-radius: var(--border-radius-default);
  }

  tbody tr:last-child td:last-child {
    border-bottom-right-radius: var(--border-radius-default);
  }

  tbody tr td {
    background: var(--color-background-white);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-normal);
    padding: 16px 12px !important;
    white-space: nowrap;
  }

  tbody tr:nth-child(even) td {
    background: var(--color-table-even);
  }

  tbody tr:hover td {
    background: var(--color-table-hover);
  }
}

.dateCol {
  white-space: nowrap;
}

.dropdown > div {
  border: 0;
  cursor: pointer;
  padding: 0;
  height: auto;
  min-height: auto;
  min-width: 200px;
  max-width: max-content;

  & > div:first-child {
    padding: 2px 0;
  }

  & > div:last-child div {
    padding: 8px 0;
  }
}

.findAndCheckbox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.costCenterClassificationCell {
  cursor: pointer;
}

.serviceInfoDraft {
  color: var(--color-danger-dark);
  display: flex;
  align-items: center;
  gap: 4px;

  &Text {
    color: var(--color-danger-dark) !important;
  }
}

.treeView {
  &Container {
    display: flex;
    gap: 32px;
    max-height: 60vh;
    width: 1040px;
    margin-top: 16px;

    & > section {
      background: var(--color-background-white);
      border: 1px solid var(--color-neutral-200);
      border-radius: var(--border-radius-input);
      padding: 16px 16px 8px;
      flex: 1;
      overflow-y: auto;
    }
  }

  &Label {
    margin-bottom: 8px;

    & span + span {
      font-weight: var(--font-weight-normal);
    }
  }
}
