.earTagNumberContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: flex-start;

  &Actions {
    display: flex;
    gap: 20px;
  }
}
