.cardContent {
  padding: 0 2em 2em;
  margin-top: -2em;

  & hr {
    margin: 0;
    margin-bottom: 16px;
    padding: 0;
  }

  & .reproductionFieldsetsDivider {
    margin: 24px 0;
    padding: 0;
  }
}

.settingsBoxedTable {
  tr:first-child td {
    border-top: var(--border-table-hairline) !important;
  }
}

.settingsExpandCard:hover {
  background: var(--color-neutral-light-100);
}

.reproductionFieldsetSingleField {
  & .reproductionFieldsetFieldLabel {
    color: var(--color-grayish);
    font-size: 18px;
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.7px;
  }

  & .reproductionFieldsetRow {
    background-color: white;
  }
}

.reproductionFieldset {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &LabelContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &Title {
    margin-left: 16px;
    margin-bottom: 26px;
  }

  &Row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-radius: 8px;
    width: 70%;
    background: var(--color-neutral-light-300);
  }

  &Field {
    &Label {
      color: var(--color-grayish);
      font-size: 16px;
      font-weight: var(--font-weight-semibold);
    }

    &Description {
      color: var(--color-grayish);
      font-size: 16px;
      font-weight: var(--font-weight-normal);
    }

    &InputContainer {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &Input {
      width: 133px;
    }

    &InputWrapper {
      height: 39px;
    }

    &InputSuffix {
      color: var(--color-grayish);
      font-size: 14px;
      font-weight: var(--font-weight-semibold);
      flex-shrink: 0;
    }
  }
}

.form {
  margin-top: 25px;
}

.tableActions {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.formFieldset {
  display: flex;
  flex-direction: row;
  gap: 24px;

  &Field {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 8px;
    flex: 1;

    & > div {
      flex: 1;
    }
  }
}

.ageGroup {
  &Row {
    &:first-child {
      margin-top: 0px;
    }

    margin-top: 15px;

    & > div {
      flex: 1;
    }
  }
}

.breedField {
  width: 317px;
}

.productCodeField {
  width: 195px;
}

.productNameField {
  width: 411px;
}

.productProviderField {
  width: 195px;
}

.temp {
  height: 49px;
  align-items: center;
  justify-content: center;

  & span {
    display: none;
  }
}

.reproductionPeriodHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    background-color: var(--color-primary);

    &:hover {
      background-color: var(--color-primary-900);
    }

    &:disabled {
      background-color: var(--color-grayish-light);
    }
  }
}

.formField {
  margin-bottom: 15px;
}
