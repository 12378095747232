.handlingGeneral {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
}

.handlingCount {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.handlingsContainer {
  margin-top: 2.5em;
}
