.productList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.productListElement {
  line-height: 32px;
  padding-bottom: 5px;
}

.empty {
  color: var(--color-neutral-200);
}