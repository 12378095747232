.serviceType {
  margin-bottom: 24px;

  & span div label span {
    border-radius: var(--border-radius-input) !important;
    padding: 24px 0 !important;
  }
}

.editForm {
  margin-top: 8px;

  & form > div {
    margin-bottom: 16px;
  }
}
