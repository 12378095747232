.formContainer {
  max-width: 75%;
}

.chipsList {
  display: flex;
  align-items: center;
  gap: 1em;
  list-style: none;
  padding: 0;
  margin: 1em 0 2em;
}

.field {
  flex: 1;
  margin-bottom: 1em;
}

.animalFather {
  margin-top: 1em;
}

.caption {
  font-family: var(--font-family);
  color: var(--default-font-color);
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-normal);
  letter-spacing: var(--letter-space-default);
  display: block;
  margin: 0.5em 0;
}

.hiddenButton {
  opacity: 0;
  visibility: hidden;
  height: 0;
}

.birthDetails {
  margin-top: 1em;
  display: flex;
  gap: 2em;
}