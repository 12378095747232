.divider {
  border: 0;
  border-top: var(--border-neutral-light);
  padding: 0.5em 0 1em;

  &Vertical {
    border-top: 0;
    border-left: var(--border-neutral-light);
  }

  &.darker {
    border-top-color: var(--color-neutral-200);
  }
}
