$padding: 1.5rem;
$padding-sm: 0.5rem;
$pagination-button-size: 30px;

.table {
  border-spacing: 0;
  width: -webkit-fill-available;

  & thead {
    & th {
      padding: $padding $padding $padding-sm 0;
      border-bottom: var(--border-neutral);
      color: var(--color-primary);
      font-weight: var(--font-weight-medium);
      letter-spacing: var(--letter-space-default);
      text-transform: uppercase;
      text-align: left;
    }
  }

  .sticky {
    position: sticky;
    background-color: var(--color-background);
    top: var(--boxed-layout-top-height);
    z-index: 99;
  }

  .mainHeader {
    background-color: var(--color-neutral-light-500);
    color: var(--color-grayish);
    text-align: center;
    padding: 1em 0 !important;
    font-size: var(--font-size-lg);
    text-transform: none;
    border-bottom: 1em solid #fff !important;
    position: static;

    &:not(:last-child) {
      border-right: 1em solid #fff;
    }
  }

  .sortable span {
    cursor: pointer;
  }

  .filter {
    position: relative;

    &:hover {
      background: var(--color-primary-30);

      > div {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .iconFilterActive {
    margin-right: 6px;
    position: relative;
    top: 4px;

    path {
      fill: var(--color-success);
    }
  }

  .iconSortable {
    margin-left: 4px;
    position: relative;
    top: 4px;

    path {
      fill: var(--color-neutral-200);
    }

    &Active {
      path {
        fill: var(--color-primary);
      }

      &Up {
        transform: rotate(180deg);
      }
    }
  }

  & tbody tr {
    &:last-of-type td {
      border: 0;
    }

    & td {
      border-bottom: var(--border-neutral-light);
      font-weight: var(--font-weight-semibold);
      padding: $padding;
      padding-left: 0;
      position: relative;
    }
  }

  th {
    white-space: nowrap;
    font-size: var(--font-size-normal);
    padding: 0 1.5em 1.5em 1.5em !important;
    color: var(--color-primary-900) !important;
    border-bottom: var(--border-table-hairline) !important;
  }

  td {
    padding: 1.5em !important;
  }

  tbody tr {
    transition: 0.1s ease-out;
    color: var(--color-grayish);
    border-radius: var(--border-radius-default);

    &:nth-child(even) {
      background-color: var(--color-table-even);
    }

    &:hover {
      background-color: var(--color-table-hover);
    }

    td:first-child {
      border-top-left-radius: var(--border-radius-default);
      border-bottom-left-radius: var(--border-radius-default);
    }

    td:last-child {
      border-top-right-radius: var(--border-radius-default);
      border-bottom-right-radius: var(--border-radius-default);
    }

    &.loading {
      pointer-events: none;

      &:nth-child(even) {
        background: none;
      }
    }
  }
}

.errorRow {
  background: var(--color-danger-table) !important;

  &:not(:last-child) > td {
    border-bottom: 0px !important;
    border-radius: 0 !important;
  }

  &:hover {
    background: var(--color-danger-table-hover) !important;
  }
}

.draftRow {
  & > td {
    background: var(--color-alert-light-200) !important;
  }

  &:not(:last-child) > td {
    border-bottom: 0px !important;
    border-radius: 0 !important;
  }
}

.newRow {
  animation: newItem 2s 2;
}

@keyframes newItem {
  50% {
    background: var(--color-primary-30);
    border-color: var(--color-success);
  }
}

.errorMessage {
  td {
    font-size: var(--font-size-xsm) !important;
    padding: 0.25rem 1.5rem !important;
    color: var(--color-danger);
    background: var(--color-danger-table);

    &:first-child {
      border-top-left-radius: 0 !important;
    }

    &:last-child {
      border-top-right-radius: 0 !important;
    }
  }
}

.navigationRow {
  cursor: pointer;
}

.headerCell {
  display: flex;
  align-items: center;
}

.iconOrderContainer {
  margin-left: 4px;
  cursor: pointer;
  display: flex;
}

.rotate {
  transform: rotate(180deg);
}

.active {
  color: var(--color-primary);
}

.pagination {
  display: flex;
  justify-content: flex-end;
  gap: 1em;

  li {
    display: flex;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $pagination-button-size;
    min-width: $pagination-button-size;
    border-radius: var(--border-radius-input);
    border: var(--border-separator);
    color: var(--color-grayish);
    font-weight: var(--font-weight-medium);
    cursor: pointer;

    &:hover {
      background: var(--color-neutral-200);
    }

    &:disabled {
      background: var(--color-neutral-200);
      cursor: default;
    }
  }

  .selected {
    border: var(--border-primary);
    color: var(--color-primary);
    background: var(--color-primary-50);

    &:hover {
      background: var(--color-primary-100);
    }
  }
}

.tableEmpty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px 0;
}
