.row {
  margin-top: 2em;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 1em;

  &:first-child {
    margin-top: 0;
  }

  &Item {
    grid-column: span 1;

    &Col2 {
      grid-column: span 2;
    }
  }
}

.field {
  flex: 1;
}

.switch {
  margin-left: 15px;
  display: flex;
  flex-direction: column;

  &Label {
    color: var(--color-primary-500);
    padding-top: 1em;
    text-transform: uppercase;
  }

  &Row {
    display: flex;
    flex: 1;
    align-items: center;
    gap: 5px;
  }
}
