.calfCard {
  &:hover {
    cursor: pointer;
  }
}

.calfStatus {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
    gap: 32px;
  }
}

.calfInfo {
  display: flex;
  gap: 30px;
  margin: 30px 0 !important;

  & caption {
    margin-bottom: 10px;
  }

  &Item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
  }
}

.calfFather {
  border-left: 1px solid var(--color-neutral-200);
  padding-left: 30px;
}

.calfEvaluations {
  max-width: 100%;
  box-sizing: border-box;
  margin-top: 15px;

  :global(.slick-disabled) {
    opacity: 0.25;
  }

  &Empty {
    display: inline-block;
    background-color: var(--color-neutral-light-500);
    color: var(--color-grayish);
    padding: 12px 16px;
    border-radius: var(--border-radius-default);
  }
}

.evaluationsCarousel {
  margin: 0 -8px;

  &Arrow {
    background: none;
    color: var(--color-grayish);
    cursor: pointer;
    padding: 0;
    border: 0;
    position: absolute;
    top: -35px;

    &Prev {
      right: 25px;
    }

    &Next {
      right: 0;
    }
  }
}

.evaluationCard {
  background: var(--color-neutral-light-300);
  border-radius: var(--border-radius-default);
  margin: 0 8px;
  display: block !important;
  width: auto !important;
  min-height: 160px;
  padding: 15px;

  &Info {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 15px;

    & caption:last-of-type {
      margin-left: auto;
    }
  }

  &Date {
    margin-left: auto;
  }
}
