.input {
  display: flex;
  align-items: center;
  gap: 0.5em;
  height: 40px;
  max-width: 120px;

  * {
    height: 40px;
    max-width: 120px;
  }
}
