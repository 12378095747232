$pagination-button-size: 30px;

.pagination {
  display: flex;
  gap: 1em;

  li {
    display: flex;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $pagination-button-size;
    min-width: $pagination-button-size;
    border-radius: var(--border-radius-input);
    border: var(--border-separator);
    color: var(--color-grayish);
    font-weight: var(--font-weight-medium);
    background: transparent;
    cursor: pointer;

    &:hover {
      background: var(--color-neutral-200);
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;

      &:hover {
        background: var(--color-neutral-light-200);
      }
    }
  }

  .selected {
    border: var(--border-primary);
    color: var(--color-primary);
    background: var(--color-primary-50);

    &:hover {
      background: var(--color-primary-100);
    }
  }
}

.buttonWrapper {
  display: flex;
  padding: 0;
  margin: 0;
  gap: 1em;
  max-width: 320px;
  overflow: auto;
}
